import styled, { css } from 'styled-components'

const TextWithIcons = css`
  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

type ContainerProps = {
  isSelected: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 0.8rem 1.6rem;

  background-color: ${(props) => props.theme.background};

  border: solid 0.2rem ${(props) => props.theme.border};
  border-radius: 1rem;

  overflow: hidden;

  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  :hover {
    border-color: ${(props) => props.theme.secondaryText};
  }

  ${(props) => {
    if (props.isSelected) {
      return css`
        border-color: ${(props) => props.theme.accent} !important;
      `
    }
  }}
`
export const Content = styled.div`
  flex: 1;
  border-radius: 1rem;

  > hr {
    margin-top: 1.8rem;
  }
`
export const Actions = styled.div`
  display: flex;

  align-items: center;
`

export const Title = styled.div`
  ${TextWithIcons};

  font-size: 1.8rem;
  font-weight: 900;
`

export const Text = styled.div`
  ${TextWithIcons};

  color: ${(props) => props.theme.secondaryText};
`
