import { Reducer } from 'redux'

import { DashboardScreenState, DASHBOARD_SCREEN_TYPES } from './types'

export const initialState: DashboardScreenState = {
  screenList: [],
  dashboardScreen: [],
}

export const DashboardScreenReducer: Reducer<DashboardScreenState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD_SCREEN_TYPES.SET_LIST: {
      return {
        ...state,
        screenList: payload.screenList,
      }
    }

    case DASHBOARD_SCREEN_TYPES.SET_SELECTED_SCREEN: {
      return {
        ...state,
        selectedScreen: payload.selectedScreen,
      }
    }

    case DASHBOARD_SCREEN_TYPES.SET_SELECTED_DASHBOARD: {
      return {
        ...state,
        selectedDashboard: payload.selectedDashboard,
      }
    }

    case DASHBOARD_SCREEN_TYPES.SET_FULL_LIST: {
      return {
        ...state,
        dashboardScreen: payload.dashboardScreen,
      }
    }

    case DASHBOARD_SCREEN_TYPES.SET_NEXT_SEQUENCE: {
      return {
        ...state,
        nextSequenceScreen: payload.nextSequenceScreen,
      }
    }

    default:
      return state
  }
}
