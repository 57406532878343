import styled from 'styled-components'

import {
  AccentButton,
  Input,
  SmallDangerButton,
  CheckBox,
} from 'src/components'

export const Container = styled.div``

export const Form = styled.form`
  background: ${(props) => props.theme.background};
  border-radius: 1rem;

  padding: 1.6rem;
`

export const StyledInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const HideFormButton = styled(SmallDangerButton)`
  width: auto;

  margin-left: auto;
  margin-bottom: 1.6rem;
`

export const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 1.6rem;
`

export const SaveDashboardTypeButton = styled(AccentButton)``
