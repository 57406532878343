import { Reducer } from 'redux'

import { DashboardState, DASHBOARD_TYPES } from './types'

export const initialState: DashboardState = {}

export const DashboardReducer: Reducer<DashboardState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD_TYPES.SET_DATA: {
      return {
        ...state,
        dashboardData: payload.dashboardData,
      }
    }

    default:
      return state
  }
}
