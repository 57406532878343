import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'

import {
  DASHBOARD_SCREEN_TYPES,
  NextSequenceScreen,
  RequestGetNextSequence,
} from './types'
import { setNextSequence } from './actions'

export function* requestGetNextSequenceWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_GET_NEXT_SEQUENCE,
    handleGetNextSequence,
  )
}

export function* handleGetNextSequence(action: RequestGetNextSequence) {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_GET_NEXT_SEQUENCE))
    const { id } = action.payload

    const routesArray = [DASHBOARD_SCREEN_ROUTES.GET_PROXIMA_SEQUENCIA, id]

    const { data }: AxiosServerResponse<any, NextSequenceScreen> = yield call(
      Server.get,
      routesArray.join('/'),
    )

    if (data.isSuccessful) {
      const getData = data.content

      if (getData) yield put(setNextSequence(getData))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_GET_NEXT_SEQUENCE))
  }
}
