import styled from 'styled-components'

import { AccentButton, Input, PageTitle } from 'src/components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;

  padding: 2.4rem;

  background: ${(props) => props.theme.background};
`

export const Form = styled.form`
  width: 100%;
  max-width: 60rem;
`

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const EmailInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SendButton = styled(AccentButton)``

export const SuccessContainer = styled.div`
  padding: 3.2rem;
`

export const SuccessTitle = styled.div`
  color: ${(props) => props.theme.primaryText};
  font-size: 3.2rem;
  font-weight: 900;

  margin-bottom: 0.8rem;

  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const SuccessMessage = styled.div`
  color: ${(props) => props.theme.secondaryText};

  margin-bottom: 2.4rem;
`

export const BackButton = styled(AccentButton)`
  span {
    margin-right: 0;
    margin-left: 1.6rem;
  }
`
