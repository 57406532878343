import { OptionsType } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { SELLER_TYPES } from 'src/config'
import { ReactSelectOption } from 'src/components'

type SellerTypeOptions = ReactSelectOption & {
  value: UncDashboard.UnionTypes.SellerType
}

export const useSellerTypes = () => {
  const { t } = useTranslation('SellerTypes')

  const sellerTypesOptions: OptionsType<SellerTypeOptions> = useMemo(() => {
    return Object.values(SELLER_TYPES).map((sellerType) => ({
      label: t(sellerType, { defaultValue: sellerType }),
      value: sellerType as UncDashboard.UnionTypes.SellerType,
    }))
  }, [t])

  return {
    sellerTypesOptions,
  }
}
