import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FiAirplay,
  FiAlignCenter,
  FiCheckCircle,
  FiHexagon,
  FiPieChart,
  FiTrash,
} from 'react-icons/fi'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
  WhiteSpinner,
} from 'src/components'
import {
  requestFetchDashboardTypes,
  setDashboardTypesList,
  DASHBOARD_TYPE_TYPES,
} from 'src/store/ducks/dashboardType'
import {
  ANALYSIS,
  requestFetchAnalysis,
  setAnalysisList,
} from 'src/store/ducks/analysis'
import {
  requestFetchNextSequence,
  requestCreateDashboardScreen,
  requestUpdateDashboardScreen,
  DASHBOARD_SCREEN_TYPES,
} from 'src/store/ducks/dashboardScreen'

import { DashboardScreenType } from '../type'

import {
  Container,
  Form,
  StyledInput,
  HideFormButton,
  SaveScreenButton,
} from './styles'

interface DashboardDashboardScreenProps {
  handleHideScreenForm: () => void
  setEditingDashboardScreen: React.Dispatch<
    React.SetStateAction<DashboardScreenType | null>
  >
  editingDashboardScreen: DashboardScreenType | null
}

const DashboardScreenForm: React.FC<DashboardDashboardScreenProps> = ({
  handleHideScreenForm,
  setEditingDashboardScreen,
  editingDashboardScreen,
}) => {
  const { t } = useTranslation(['DashboardScreenForm', 'Glossary'])
  const dispatch = useDispatch()

  const nextSequenceScreenValue = useTypedSelector(
    ({ DashboardScreen }) => DashboardScreen.nextSequenceScreen?.DASHTELAS_SEQ,
  )

  const isLoading = useIsLoading(
    DASHBOARD_SCREEN_TYPES.REQUEST_CREATE,
    DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE,
  )

  const isEditing = useMemo(() => !!editingDashboardScreen, [
    editingDashboardScreen,
  ])

  const descriptionRef = useRef<HTMLInputElement | null>(null)

  const isLoadingDashboardTypes = useIsLoading(
    DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE,
  )
  const isLoadingAnalysis = useIsLoading(ANALYSIS.REQUEST_FETCH_ANALYSIS)

  const [
    selectedDashboard,
    setSelectedDashboard,
  ] = useState<ReactSelectOption | null>(null)

  const [selectedAnalysis, setSelectedAnalysis] = useState<
    ReactSelectOption[] | null
  >(null)

  const [dashboardSequence, setDashboardSequence] = useState<string>('')

  const [errors, setErrors] = useState({
    isDashboardNotSelected: false,
    isDescriptionEmpty: false,
    isSequenceEmpty: false,
    isAnalysisNotSelected: false,
  })

  const getUncontrolledValues = () => {
    const dashboardDescription = descriptionRef.current?.value || ''

    return {
      dashboardDescription,
    }
  }

  const dashboardTypes = useTypedSelector(
    ({ DashboardType }) => DashboardType.dashboardTypes,
  )
  const analysis = useTypedSelector(
    ({ AnalysisReducer }) => AnalysisReducer.analysis,
  )

  const dashboardTypeOptions: ReactSelectOption[] = useMemo(() => {
    return dashboardTypes.map(({ dash_descricao, dash_codigo }) => ({
      label: dash_descricao,
      value: String(dash_codigo),
    }))
  }, [dashboardTypes])

  const analysisOptions: ReactSelectOption[] = useMemo(() => {
    return analysis.map(({ analise_descricao, analise_codigo }) => ({
      label: analise_descricao,
      value: String(analise_codigo),
    }))
  }, [analysis])

  const handleClearForm = () => {
    if (descriptionRef.current) descriptionRef.current.value = ''
    setDashboardSequence('')
    setSelectedDashboard(null)
  }

  const handleValidation = (): boolean => {
    const { dashboardDescription } = getUncontrolledValues()

    const errorsObject: typeof errors = {
      isDashboardNotSelected: !selectedDashboard,
      isDescriptionEmpty: !dashboardDescription.trim(),
      isSequenceEmpty: !dashboardSequence.trim(),
      isAnalysisNotSelected: !selectedAnalysis,
    }

    setErrors(errorsObject)
    return Object.values(errorsObject).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (handleValidation()) {
      const { dashboardDescription } = getUncontrolledValues()
      const dashboardSelected = selectedDashboard?.value

      if (!selectedAnalysis) return
      const analysisList: UncDashboard.ComposedTypes.teladash_analise[] = []

      selectedAnalysis.forEach((Analysis) => {
        analysisList.push({ analise_codigo: Analysis.value })
      })

      if (!dashboardSequence) return
      if (!dashboardSelected) return
      if (editingDashboardScreen) {
        dispatch(
          requestUpdateDashboardScreen({
            dashtelas_codigo: editingDashboardScreen.dashtelas_codigo,
            teladash_codigo: editingDashboardScreen.teladash_codigo,
            teladash_analise: analysisList,
            teladash_descricao: dashboardDescription,
            dashtelas_seq: Number(dashboardSequence),
            dash_codigo: Number(dashboardSelected),
            successCallback() {
              setEditingDashboardScreen(null)
            },
          }),
        )
      } else {
        dispatch(
          requestCreateDashboardScreen({
            teladash_analise: analysisList,
            dash_codigo: Number(dashboardSelected),
            teladash_descricao: dashboardDescription,
            dashtelas_seq: Number(dashboardSequence),
            successCallback: handleClearForm,
          }),
        )
      }
    }
  }

  // const handleSelectAll = () => {
  //   const analysisObject: { [key: string]: boolean } = {}

  //   if (!selectedAnalysis) return
  //   selectedAnalysis.forEach((Analysis) => {
  //     const analysisObjectClone = { ...Analysis }
  //     const analysisItem = analysisObjectClone.value
  //     analysisObject[analysisItem] = !!analysisItem
  //   })
  //   const analysisListObject = JSON.stringify(analysisObject)

  //   setSelectedAnalysis(analysisListObject)
  // }

  useEffect(() => {
    if (selectedDashboard && !editingDashboardScreen) {
      dispatch(
        requestFetchNextSequence({ id: Number(selectedDashboard?.value) }),
      )
    }
  }, [dispatch, editingDashboardScreen, selectedDashboard])

  useEffect(() => {
    if (nextSequenceScreenValue) {
      setDashboardSequence(nextSequenceScreenValue)
    }
  }, [nextSequenceScreenValue])

  useEffect(() => {
    if (editingDashboardScreen) {
      const dashboardScreenFound = dashboardTypes.find(
        ({ dash_codigo }) => dash_codigo === editingDashboardScreen.dash_codigo,
      )

      if (!dashboardScreenFound) return

      setSelectedDashboard({
        label: dashboardScreenFound.dash_descricao,
        value: String(dashboardScreenFound.dash_codigo),
      })
    }
  }, [dashboardTypes, editingDashboardScreen])

  useEffect(() => {
    if (editingDashboardScreen && descriptionRef.current) {
      const eleito = editingDashboardScreen.teladash_descricao

      descriptionRef.current.value = eleito
    }
  }, [editingDashboardScreen])

  useEffect(() => {
    if (editingDashboardScreen) {
      const item = editingDashboardScreen.LISTA_ANALISES.map((analysis) => ({
        label: analysis.analise_descricao,
        value: String(analysis.analise_codigo),
      }))

      setSelectedAnalysis(item)
    }
  }, [analysisOptions, editingDashboardScreen])

  useEffect(() => {
    if (editingDashboardScreen) {
      const sequence = editingDashboardScreen.dashtelas_seq

      setDashboardSequence(String(sequence))
    }
  }, [analysisOptions, editingDashboardScreen])

  useEffect(() => {
    dispatch(requestFetchDashboardTypes())
    dispatch(requestFetchAnalysis())
    return () => {
      dispatch(setDashboardTypesList([]))
      dispatch(setAnalysisList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <HideFormButton
        onClick={() => {
          handleHideScreenForm()
          setEditingDashboardScreen(null)
        }}
      >
        {t(isEditing ? 'cancelEditingButton' : 'cancelScreenCreation')}
        <FiTrash />
      </HideFormButton>

      <Form onSubmit={handleSubmit} noValidate>
        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="dashboard">
              <FiHexagon />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('Dashboard')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="dashboard"
              name="dashboard"
              options={dashboardTypeOptions}
              value={selectedDashboard}
              onChange={(option) =>
                setSelectedDashboard(option as ReactSelectOption)
              }
              placeholder={t('DashboardPh')}
              isLoading={isLoadingDashboardTypes}
              noOptionsMessage={() => t('dashboardTypesFound')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isDashboardNotSelected}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="analysis">
              <FiPieChart />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('AnalysisLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="analysis"
              name="analysis"
              options={analysisOptions}
              value={selectedAnalysis}
              onChange={(option) =>
                setSelectedAnalysis(option as ReactSelectOption[])
              }
              placeholder={t('AnalysisPh')}
              isLoading={isLoadingAnalysis}
              noOptionsMessage={() => t('analysisNotFound')}
              isMulti
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isAnalysisNotSelected}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="description">
              <FiAlignCenter />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('descriptionLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              ref={descriptionRef}
              id="description"
              type="text"
              autoCapitalize="off"
              placeholder={t('descriptionPh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isDescriptionEmpty}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="sequence">
              <FiAirplay />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('dashboardSequenceLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              defaultValue={dashboardSequence}
              id="sequence"
              type="number"
              autoCapitalize="off"
              placeholder={t('dashboardSequencePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isSequenceEmpty}
        />

        <SaveScreenButton type="submit" disabled={isLoading}>
          <span>{t('saveScreen')}</span>
          {isLoading ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveScreenButton>
      </Form>
    </Container>
  )
}

export default DashboardScreenForm
