import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'

import { DASHBOARD_SCREEN_TYPES } from './types'
import { setFullDashboardScreenList } from './actions'

export function* requestFetchFullDashboardScreensWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL,
    handleFetchFullDashboardScreen,
  )
}

export function* handleFetchFullDashboardScreen() {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL))

    const {
      data,
    }: AxiosServerResponse<UncDashboard.ComposedTypes.GetDashboardScreen> =
      yield call(Server.put, DASHBOARD_SCREEN_ROUTES.GET_LISTA, {})

    if (data.isSuccessful) {
      const dataScreen = data.data

      const dashboardList = dataScreen.map((item) => {
        const screenList = item?.LISTA_TELAS?.map((screen) => {
          const [analysisData] = screen.LISTA_ANALISES || []

          const screenClone = {
            ...screen,
            LISTA_ANALISES: analysisData?.DADOS || [],
          }

          return screenClone
        })

        return { ...item, LISTA_TELAS: screenList || [] }
      })
      yield put(setFullDashboardScreenList(dashboardList))
    } else {
      yield put(setFullDashboardScreenList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL))
  }
}
