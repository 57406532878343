import React from 'react'
import { useTheme } from 'styled-components'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Cell,
} from 'recharts'
import { useTranslation } from 'react-i18next'

import { formatNumber } from 'src/utils'

import { Chart } from '../styles'

import { BarLegend } from './styles'
import { ValueType } from 'recharts/types/component/DefaultTooltipContent'

export interface DashboardBarChartLegendedProps {
  data: UncDashboard.ComposedTypes.DashboardDataValues[]
  title: string
  analysisCode: number
}

export const DashboardBarChartLegended: React.FC<
  DashboardBarChartLegendedProps
> = ({ data, title, analysisCode }) => {
  const { t } = useTranslation('Glossary')
  const theme = useTheme()

  function handleFormatValuesCurrency2<TValue extends ValueType>(
    value: TValue,
  ) {
    return `R$ ${formatNumber(Number(value), { defaultValue: String(value) })}`
  }

  const handleFormatValuesCurrency = (value: number) => {
    return `R$ ${formatNumber(value, { defaultValue: String(value) })}`
  }

  function handleFormatValues2<TValue extends ValueType>(value: TValue) {
    return `R$ ${formatNumber(Number(value), { defaultValue: String(value) })}`
  }

  const handleFormatValues = (value: number) => {
    return `${formatNumber(value, { defaultValue: String(value) })}`
  }

  const colorArray: string[] = React.useMemo(() => {
    return data.map((_, index) => {
      return `hsl(${360 - index * 25}, 90%, 40%)`
    })
  }, [data])

  const renderTooltip = (analysisCode: number) => {
    if (analysisCode === 34) return <Tooltip formatter={handleFormatValues2} />

    return <Tooltip formatter={handleFormatValuesCurrency2} />
  }

  const renderYAxis = (analysisCode: number) => {
    if (analysisCode === 34) return <YAxis tickFormatter={handleFormatValues} />

    return <YAxis tickFormatter={handleFormatValuesCurrency} />
  }

  const returnValue = (
    entry: UncDashboard.ComposedTypes.DashboardDataValues,
    analysisCode: number,
  ) => {
    if (analysisCode === 34) return handleFormatValues(entry.serie_valor || 0)

    return handleFormatValuesCurrency(entry.serie_valor || 0)
  }

  return (
    <Chart title={title}>
      <ResponsiveContainer minHeight="30rem">
        <BarChart
          data={data}
          margin={{ bottom: 0, left: 80, right: 8, top: 16 }}
        >
          <XAxis dataKey="serie_nome" hide={true} />
          <CartesianGrid strokeDasharray="3 3" />
          {renderTooltip(analysisCode)}
          {renderYAxis(analysisCode)}
          <Bar name={t('value')} dataKey="serie_valor">
            {data.map((entry, index) => (
              <Cell key={index} fill={colorArray[index % colorArray.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {data.map((entry, index) => {
        return (
          <BarLegend
            key={`bar-legend-${index}`}
            label={entry.serie_nome || ''}
            color={colorArray[index % colorArray.length]}
            value={returnValue(entry, analysisCode)}
          />
        )
      })}
    </Chart>
  )
}
