import React from 'react'

import { useSetBrowserTabTitle } from 'src/hooks'
// import { PageAccessVerifier } from 'src/components'

import Settings from './Settings'
import ValidationWrapper from './ValidationWrapper'
import DataVisualization from './DataVisualization'
import useFetchDashboardScreens from './useFetchDashboardScreens'
import useGetDataWhenSelectScreen from './useGetDataWhenSelectScreen'
import { Container } from './styles'

const Dashboard: React.FC = () => {
  useSetBrowserTabTitle('dashboard')

  useFetchDashboardScreens()
  useGetDataWhenSelectScreen()

  return (
    // <PageAccessVerifier page={157}>
    <Container>
      <Settings />
      <ValidationWrapper>
        <DataVisualization />
      </ValidationWrapper>
    </Container>
    // </PageAccessVerifier>
  )
}

export default Dashboard
