import styled from 'styled-components'

import {
  DashboardScreen,
  SpinnerWithText,
  DashboardScreenItem,
  AnalysisScreen,
} from 'src/components'

export const StyledDashboard = styled(DashboardScreen)`
  margin: 2.4rem 0 0.8rem 0;
`

export const StyledSpinnerWithText = styled(SpinnerWithText)`
  margin: 2.4rem 0;
`

export const StyledDashboardItem = styled(DashboardScreenItem)`
  margin-bottom: 0.8rem;
`

export const StyledAnalysisScreen = styled(AnalysisScreen)`
  margin-bottom: 0.8rem;
`

export const List = styled.div`
  margin-top: 2.4rem;
`

export const MoreDetailsText = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
`

export const Actions = styled.div`
  display: flex;

  > :first-child {
    margin: 0 0.8rem !important;
  }
`
