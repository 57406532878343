export enum DASHBOARD_TYPE_TYPES {
  REQUEST_FETCH_DASHBOARD_TYPE = '@DASHBOARD_TYPE/REQUEST_FETCH_DASHBOARD_TYPES',
  REQUEST_CREATE_DASHBOARD_TYPE = '@DASHBOARD_TYPE/REQUEST_CREATE_DASHBOARD_TYPE',
  REQUEST_UPDATE_DASHBOARD_TYPE = '@DASHBOARD_TYPE/REQUEST_UPDATE_DASHBOARD_TYPE',
  REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION = '@DASHBOARD_TYPE/REQUEST_TOGGLE_DASHBOARD_SITUATION',
  REQUEST_REMOVE_DASHBOARD_TYPE = '@DASHBOARD_TYPE/REQUEST_REMOVE_DASHBOARD_TYPE',
  SET_DASHBOARD_TYPES_LIST = '@DASHBOARD_TYPE/SET_DASHBOARD_TYPES_LIST',
  GET_DASHBOARD_TYPES_LIST = '@DASHBOARD_TYPE/GET_DASHBOARD_TYPES_LIST',
}

export interface DashboardTypeState {
  dashboardTypes: UncDashboard.Dashboard[]
}

export interface RequestFetchDashboardTypesAction {
  type: DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE
}

export interface SetDashboardTypesListAction {
  type: DASHBOARD_TYPE_TYPES.SET_DASHBOARD_TYPES_LIST
  payload: {
    dashboardTypes: UncDashboard.Dashboard[]
  }
}

export interface RequestCreateDashboardTypeAction {
  type: DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE
  payload: {
    description: string
    duration: string
    dashboardCategory: UncDashboard.UnionTypes.DashboardCategory
    isAutomatic: boolean
    successCallback?: () => void
  }
}

export interface RequestUpdateDashboardTypeAction {
  type: DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE
  payload: {
    id: number
    description: string
    situation: UncDashboard.UnionTypes.ActiveOrInactive
    duration: string
    dashboardCategory: UncDashboard.UnionTypes.DashboardCategory
    isAutomatic: boolean
    successCallback?: () => void
  }
}

export interface RequestToggleDashboardTypeSituationAction {
  type: DASHBOARD_TYPE_TYPES.REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION
  payload: {
    id: number
    situation: UncDashboard.UnionTypes.ActiveOrInactive
  }
}

export interface RequestRemoveDashboardTypeAction {
  type: DASHBOARD_TYPE_TYPES.REQUEST_REMOVE_DASHBOARD_TYPE
  payload: {
    id: number
  }
}
