import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { DASHBOARD_TYPE_TYPES } from './types'
import { setDashboardTypesList } from './actions'

export function* requestFetchDashboardTypesWatcher() {
  yield takeLatest(
    DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE,
    handleFetchDashboardTypes,
  )
}

export function* handleFetchDashboardTypes() {
  try {
    yield put(addLoading(DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE))

    const { data }: AxiosServerResponse<UncDashboard.Dashboard> = yield call(
      Server.put,
      DASHBOARD_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setDashboardTypesList(data.data))
    } else {
      yield put(setDashboardTypesList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE))
  }
}
