import { CONSTANTS } from 'src/config'

export enum SERVER_HEADERS {
  ACCEPT = 'Accept',
  CONTENT_TYPE = 'Content-Type',
  REPRES_CODIGO = 'repres_codigo',
  PUBUSUARIO = 'PubUsuario',
  PUBEMPRESA = 'PubEmpresa',
  PUBUSUARIOALTERAEMPRESA = 'PubUsuarioAlteraEmpresa',
  PUBUSUARIOVISUALIZAREMPLOG = 'PubUsuarioVisualizarEmpLog',
  PUBSISTEMA = 'PubSistema',
  PUBSISTEMADESCRICAO = 'PubSistemaDescricao',
  PUBUPPERCASE = 'PubUpperCase',
  PUBCLIENTEREGISTRO = 'PubClienteRegistro',
  PUBMACADDRESS = 'PubMacAddress',
}

export const getDefaultHeaders = () => ({
  [SERVER_HEADERS.ACCEPT]: 'application/json',
  [SERVER_HEADERS.CONTENT_TYPE]: 'application/json',
  [SERVER_HEADERS.PUBUPPERCASE]: 'S',
  [SERVER_HEADERS.PUBSISTEMA]: CONSTANTS.SYSTEM,
  [SERVER_HEADERS.PUBSISTEMADESCRICAO]: CONSTANTS.SYSTEM,
})
