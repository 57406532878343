import {
  DASHBOARD_TYPE_TYPES,
  RequestFetchDashboardTypesAction,
  SetDashboardTypesListAction,
  RequestCreateDashboardTypeAction,
  RequestUpdateDashboardTypeAction,
  RequestToggleDashboardTypeSituationAction,
  RequestRemoveDashboardTypeAction,
} from './types'

export const requestFetchDashboardTypes = (): RequestFetchDashboardTypesAction => ({
  type: DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE,
})

export const setDashboardTypesList = (
  dashboardTypes: UncDashboard.Dashboard[],
): SetDashboardTypesListAction => ({
  type: DASHBOARD_TYPE_TYPES.SET_DASHBOARD_TYPES_LIST,
  payload: {
    dashboardTypes,
  },
})

export const requestCreateDashboardType = (
  payload: RequestCreateDashboardTypeAction['payload'],
): RequestCreateDashboardTypeAction => ({
  type: DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE,
  payload,
})

export const requestUpdateDashboardType = (
  payload: RequestUpdateDashboardTypeAction['payload'],
): RequestUpdateDashboardTypeAction => ({
  type: DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE,
  payload,
})

export const requestToggleDashboardTypeSituation = (
  payload: RequestToggleDashboardTypeSituationAction['payload'],
): RequestToggleDashboardTypeSituationAction => ({
  type: DASHBOARD_TYPE_TYPES.REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION,
  payload,
})

export const requestRemoveDashboardType = (
  payload: RequestRemoveDashboardTypeAction['payload'],
): RequestRemoveDashboardTypeAction => ({
  type: DASHBOARD_TYPE_TYPES.REQUEST_REMOVE_DASHBOARD_TYPE,
  payload,
})
