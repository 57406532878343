import React from 'react'
import { useDispatch } from 'react-redux'
import { FiPieChart } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { OptionsType } from 'react-select'

import { useIsLoading, useTypedSelector } from 'src/hooks'
import { DefaultLabel, ReactSelectOption, SnowSelect } from 'src/components'
import {
  DASHBOARD_SCREEN_TYPES,
  setSelectedDashboardScreen,
} from 'src/store/ducks/dashboardScreen'

import { Container, Label, StyledInput } from './styles'

const Settings: React.FC = () => {
  const { t } = useTranslation(['Dashboard', 'DashboardScreen', 'Glossary'])
  const dispatch = useDispatch()

  const isLoadingScreens = useIsLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH)

  const options: OptionsType<ReactSelectOption> = useTypedSelector(
    ({ DashboardScreen }) =>
      DashboardScreen.screenList.map(
        ({ teladash_codigo, teladash_descricao }) => ({
          label: teladash_descricao,
          value: String(teladash_codigo),
        }),
      ),
  )

  const selectedScreen = useTypedSelector(
    ({ DashboardScreen }) => DashboardScreen.selectedScreen,
  )

  const handleChangeDashboard = (option: any) => {
    const DashboardScreenOption: ReactSelectOption = option
    dispatch(setSelectedDashboardScreen(DashboardScreenOption))
  }

  return (
    <Container>
      <Label>{t('dashboardSettingsLabel')}</Label>

      <StyledInput
        labelComponent={
          <DefaultLabel>
            <FiPieChart />
            <span>{t('DashboardScreen:dashboardScreenLabel')}</span>
          </DefaultLabel>
        }
        inputComponent={
          <SnowSelect
            id="dashboard"
            name="dashboard"
            options={options}
            value={selectedScreen}
            isLoading={isLoadingScreens}
            onChange={handleChangeDashboard}
            loadingMessage={() => t('Glossary:loading')}
            placeholder={t('DashboardScreen:dashboardScreenPh')}
            noOptionsMessage={() =>
              t('DashboardScreen:noDashboardScreensFound')
            }
          />
        }
      />
    </Container>
  )
}

export default Settings
