import {
  DASHBOARD_SCREEN_TYPES,
  SelectedDashboardScreen,
  RequestFetchDashboardScreensAction,
  SetDashboardScreenListAction,
  SetSelectedDashboardScreenAction,
  RequestFetchDashboardScreens,
  SetFullDashboardScreenListAction,
  RequestCreateDashboardScreenAction,
  RequestUpdateDashboardScreenAction,
  RequestRemoveDashboardScreenAction,
  RequestGetNextSequence,
  SetNextSequenceAction,
  NextSequenceScreen,
  SelectedDashboard,
  SetSelectedDashboardAction,
} from './types'

export const requestFetchDashboardScreens = (): RequestFetchDashboardScreensAction => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_FETCH,
})

export const requestFetchFullDashboardScreen = (): RequestFetchDashboardScreens => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL,
})

export const requestFetchNextSequence = (
  payload: RequestGetNextSequence['payload'],
): RequestGetNextSequence => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_GET_NEXT_SEQUENCE,
  payload,
})

export const requestCreateDashboardScreen = (
  payload: RequestCreateDashboardScreenAction['payload'],
): RequestCreateDashboardScreenAction => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_CREATE,
  payload,
})

export const requestUpdateDashboardScreen = (
  payload: RequestUpdateDashboardScreenAction['payload'],
): RequestUpdateDashboardScreenAction => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE,
  payload,
})

export const requestRemoveDashboardScreen = (
  payload: RequestRemoveDashboardScreenAction['payload'],
): RequestRemoveDashboardScreenAction => ({
  type: DASHBOARD_SCREEN_TYPES.REQUEST_REMOVE,
  payload,
})

export const setSelectedDashboardScreen = (
  selectedScreen?: SelectedDashboardScreen,
): SetSelectedDashboardScreenAction => ({
  type: DASHBOARD_SCREEN_TYPES.SET_SELECTED_SCREEN,
  payload: {
    selectedScreen,
  },
})

export const setSelectedDashboard = (
  selectedDashboard?: SelectedDashboard,
): SetSelectedDashboardAction => ({
  type: DASHBOARD_SCREEN_TYPES.SET_SELECTED_DASHBOARD,
  payload: {
    selectedDashboard,
  },
})

export const setDashboardScreenList = (
  screenList: UncDashboard.DashboardScreen[],
): SetDashboardScreenListAction => ({
  type: DASHBOARD_SCREEN_TYPES.SET_LIST,
  payload: {
    screenList,
  },
})

export const setNextSequence = (
  nextSequenceScreen: NextSequenceScreen,
): SetNextSequenceAction => ({
  type: DASHBOARD_SCREEN_TYPES.SET_NEXT_SEQUENCE,
  payload: {
    nextSequenceScreen,
  },
})

export const setFullDashboardScreenList = (
  dashboardScreen: any[],
): SetFullDashboardScreenListAction => ({
  type: DASHBOARD_SCREEN_TYPES.SET_FULL_LIST,
  payload: {
    dashboardScreen,
  },
})
