import React from 'react'

import { formatNumber } from 'src/utils'

import { Cards, DashboardCard } from './styles'

export interface DashboardDataCardsProps {
  cardList: UncDashboard.ComposedTypes.DashboardDataAnalysis[]
}

export const DashboardDataCards: React.FC<DashboardDataCardsProps> = ({
  cardList,
}) => {
  if (cardList.length === 0) return null

  return (
    <Cards>
      {cardList.map(({ DADOS, analise_codigo, analise_descricao }) => {
        let cardValue = 0

        if (DADOS && Array.isArray(DADOS) && DADOS.length) {
          const [chartData] = DADOS
          cardValue = chartData.card_valor || 0
        }

        return (
          <DashboardCard
            key={analise_codigo}
            title={analise_descricao}
            data={`R$ ${formatNumber(cardValue || 0, {
              defaultValue: String(cardValue),
            })}`}
          />
        )
      })}
    </Cards>
  )
}
