import React from 'react'

import { MainContentRoutes } from 'src/routes'

import useResponsiveLogic from './useResponsiveLogic'
import MainDrawer from './MainDrawer'
import MainNavbar from './MainNavbar'
import { Container, Content, RoutesContainer } from './styles'

const Main: React.FC = () => {
  const {
    handleToggleDrawer,
    isResponsiveMode,
    isShowingDrawer,
  } = useResponsiveLogic()

  return (
    <Container>
      <MainDrawer
        handleToggleDrawer={handleToggleDrawer}
        isResponsiveMode={isResponsiveMode}
        isShowingDrawer={isShowingDrawer}
      />

      <Content>
        <MainNavbar handleToggleDrawer={handleToggleDrawer} />
        <RoutesContainer>
          <MainContentRoutes />
        </RoutesContainer>
      </Content>
    </Container>
  )
}

export default Main
