import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FiEdit, FiLock } from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  SnowInput,
  WhiteSpinner,
} from 'src/components'
import { useIsLoading, useSetBrowserTabTitle } from 'src/hooks'
import { PASSWORD_TYPES, requestResetPassword } from 'src/store/ducks/password'

import {
  Container,
  StyledPageTitle,
  Form,
  PasswordInput,
  SaveButton,
} from './styles'

type ResetPasswordParams = {
  hash: string
}

const ResetPassword: React.FC = () => {
  useSetBrowserTabTitle('resetPassword')

  const { t } = useTranslation(['ResetPassword', 'Error', 'Glossary'])
  const { hash } = useParams<ResetPasswordParams>()
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useIsLoading(PASSWORD_TYPES.REQUEST_RESET_PASSWORD)

  const passwordRef = useRef<HTMLInputElement | null>(null)
  const confirmPasswordRef = useRef<HTMLInputElement | null>(null)

  const [errors, setErrors] = useState({
    isPasswordEmpty: false,
    isConfirmPasswordEmpty: false,
    isPasswordsNotEqual: false,
  })

  const getValues = () => {
    const password = passwordRef.current?.value || ''
    const confirmPassword = confirmPasswordRef.current?.value || ''

    return {
      password,
      confirmPassword,
    }
  }

  const handleValidate = (): boolean => {
    const { password, confirmPassword } = getValues()

    const errorState: typeof errors = {
      isPasswordEmpty: !password.trim(),
      isConfirmPasswordEmpty: !confirmPassword.trim(),
      isPasswordsNotEqual: password !== confirmPassword,
    }

    setErrors(errorState)
    return Object.values(errorState).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (handleValidate()) {
      const { password, confirmPassword } = getValues()

      dispatch(
        requestResetPassword({
          hash,
          password,
          confirmPassword,
          successCallback() {
            history.replace('/')
          },
        }),
      )
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} noValidate>
        <StyledPageTitle
          title={t('title')}
          subtitle={t('subtitle')}
          iconComponent={<FiEdit />}
        />

        <PasswordInput
          labelComponent={
            <DefaultLabel htmlFor="password">
              <FiLock />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('newPasswordLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="password"
              ref={passwordRef}
              type="password"
              autoCapitalize="off"
              placeholder={t('newPasswordPh')}
              disabled={isLoading}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isPasswordEmpty}
        />

        <PasswordInput
          labelComponent={
            <DefaultLabel htmlFor="confirmPassword">
              <FiLock />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('confirmPasswordLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="confirmPassword"
              ref={confirmPasswordRef}
              type="password"
              autoCapitalize="off"
              placeholder={t('confirmPasswordPh')}
              disabled={isLoading}
            />
          }
          errorComponent={
            <DefaultError>
              {t(
                !errors.isConfirmPasswordEmpty && errors.isPasswordsNotEqual
                  ? 'Error:passwordsNotEqual'
                  : 'Error:emptyFieldError',
              )}
            </DefaultError>
          }
          showError={
            errors.isConfirmPasswordEmpty || errors.isPasswordsNotEqual
          }
        />

        <SaveButton type="submit" disabled={isLoading}>
          <span>{t('saveButton')}</span>
          {isLoading ? <WhiteSpinner /> : <FiEdit />}
        </SaveButton>
      </Form>
    </Container>
  )
}

export default ResetPassword
