import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiTrash } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import {
  Spinner,
  DefaultTooltip,
  ConfirmModal,
  DangerRoundIconButton,
  AnalysisScreen,
} from 'src/components'
import { useIsLoading, useRebuildTooltips, useTypedSelector } from 'src/hooks'
import {
  requestFetchFullDashboardScreen,
  requestRemoveDashboardScreen,
  setFullDashboardScreenList,
  DASHBOARD_SCREEN_TYPES,
} from 'src/store/ducks/dashboardScreen'

import { DashboardScreenType } from '../type'

import {
  List,
  StyledSpinnerWithText,
  StyledDashboard,
  StyledDashboardItem,
  MoreDetailsText,
  Actions,
} from './styles'

interface DashboardScreenListProps {
  setEditingDashboardScreen: React.Dispatch<
    React.SetStateAction<DashboardScreenType | null>
  >
  editingDashboardScreen: DashboardScreenType | null
}

const DashboardScreenList: React.FC<DashboardScreenListProps> = ({
  setEditingDashboardScreen,
  editingDashboardScreen,
}) => {
  const dispatch = useDispatch()

  const dashboardScreens = useTypedSelector(({ DashboardScreen }) => {
    return DashboardScreen.dashboardScreen
  })
  const { t } = useTranslation([
    'CreateDashboardType',
    'DashboardCategories',
    'DashboardScreen',
    'DateTime',
    'Glossary',
  ])

  const [dashboardScreenId, setDashboardScreenId] = useState<number | null>(
    null,
  )

  const isLoading = useIsLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL)
  useRebuildTooltips()

  const handleDeleteDashboardScreen = () => {
    if (!dashboardScreenId) return

    dispatch(
      requestRemoveDashboardScreen({
        id: dashboardScreenId,
      }),
    )
  }

  useEffect(() => {
    dispatch(requestFetchFullDashboardScreen())

    return () => {
      dispatch(setFullDashboardScreenList([]))
    }
  }, [dispatch])

  return (
    <>
      <ConfirmModal
        confirmButtonType="danger"
        isShowing={!!dashboardScreenId}
        title={t('deleteModalTitle')}
        message={t('deleteModalMessage')}
        onConfirm={handleDeleteDashboardScreen}
        cancelButtonText={t('Glossary:cancel')}
        confirmButtonText={t('Glossary:delete')}
        iconComponent={<FiTrash size="2.4rem" />}
        handleHideModal={() => setDashboardScreenId(null)}
      />

      <DefaultTooltip />

      {isLoading ? (
        <StyledSpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('Glossary:loading')}</span>
        </StyledSpinnerWithText>
      ) : (
        <List>
          {dashboardScreens.map((dashboardType) => {
            const {
              dash_codigo,
              dash_situacao,
              dash_tipo,
              dash_descricao,
              dash_tempotelas,
            } = dashboardType

            const isActive = dash_situacao === 'A'

            const categoryText = t('listItemCategory', {
              category: t(`DashboardCategories:${dash_tipo}`),
            })

            const durationText = t('listItemDuration', {
              duration: t('DateTime:secondsShort', {
                seconds: dash_tempotelas || 0,
              }),
            })

            const activeOrInactive = t('listItemSituation', {
              situation: t(isActive ? 'Glossary:active' : 'Gloss0ary:inactive'),
            })

            return (
              <>
                <StyledDashboard
                  key={dash_codigo}
                  isActive={isActive}
                  category={categoryText}
                  duration={durationText}
                  description={dash_descricao}
                  situationText={activeOrInactive}
                  moreDetails={
                    <MoreDetailsText>
                      {t('DashboardScreen:allScreenList')}
                    </MoreDetailsText>
                  }
                >
                  {dashboardType.LISTA_TELAS.map((screen) => {
                    const {
                      dashtelas_seq,
                      dashtelas_codigo,
                      LISTA_ANALISES,
                      teladash_descricao,
                    } = screen

                    const sequenceText = t('Glossary:sequence', {
                      sequence: dashtelas_seq,
                    })

                    const isSelected =
                      dashtelas_codigo ===
                      editingDashboardScreen?.dashtelas_codigo

                    const handleDeleteDashboardType = (e: React.MouseEvent) => {
                      e.stopPropagation()
                      setDashboardScreenId(dashtelas_codigo)
                    }

                    const handleSelectDashboard = () => {
                      setEditingDashboardScreen(
                        isSelected
                          ? null
                          : ({
                              ...dashboardType,
                              ...screen,
                            } as DashboardScreenType),
                      )
                    }

                    return (
                      <StyledDashboardItem
                        key={dashtelas_codigo}
                        isSelected={isSelected}
                        onClick={handleSelectDashboard}
                        title={teladash_descricao}
                        analysisShow={t('DashboardScreen:allAnalysis')}
                        sequence={sequenceText}
                        rightSideComponent={
                          <Actions>
                            <DangerRoundIconButton
                              data-tip={t('Glossary:delete')}
                              onClick={handleDeleteDashboardType}
                            >
                              <FiTrash />
                            </DangerRoundIconButton>
                          </Actions>
                        }
                      >
                        {LISTA_ANALISES.map((analysis) => {
                          return (
                            <AnalysisScreen
                              key={analysis.analise_codigo}
                              analysisType={analysis.tipoanalise_descricao}
                              name={analysis.analise_descricao}
                            />
                          )
                        })}
                      </StyledDashboardItem>
                    )
                  })}
                </StyledDashboard>
              </>
            )
          })}
        </List>
      )}
    </>
  )
}
export default DashboardScreenList
