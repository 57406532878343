import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'src/hooks'
import { requestFetchSummary, setSummary } from 'src/store/ducks/autoDashboard'

export default () => {
  const dispatch = useDispatch()

  const selectedScreen = useTypedSelector(
    ({ DashboardScreen }) => DashboardScreen.selectedDashboard,
  )

  useEffect(() => {
    dispatch(
      requestFetchSummary(
        selectedScreen?.value === ''
          ? {}
          : { dash_codigo: selectedScreen?.value },
      ),
    )

    return () => {
      dispatch(setSummary(undefined))
    }
  }, [dispatch, selectedScreen])
}
