import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { setScreenAtIndex } from './actions'
import { AUTO_DASHBOARD_TYPES } from './types'
import {
  getCurrentScreenIndex,
  getScreenIdCurried,
  getNextScreenIndex,
  getPreviousScreenIndex,
} from './selects'

type RequestData = Pick<UncDashboard.DashboardScreen, 'teladash_codigo'>
type Response = AxiosServerResponse<UncDashboard.ComposedTypes.DashboardData>

export function* handleFetchCurrentScreenData() {
  const currentScreenIndex: number = yield select(getCurrentScreenIndex)
  const currentScreenId: number = yield select(
    getScreenIdCurried(currentScreenIndex),
  )

  const currentScreenRequestData: RequestData = {
    teladash_codigo: currentScreenId,
  }

  const { data }: Response = yield call(
    Server.put,
    DASHBOARD_ROUTES.GET_GRAFICOS,
    currentScreenRequestData,
  )

  if (!data.isSuccessful) return

  const firstData = data.getFirstData()
  const screenList = firstData?.LISTA_TELAS
  if (!screenList) return

  const [firstScreen] = screenList
  if (!firstScreen) return

  yield put(setScreenAtIndex(firstScreen, currentScreenIndex))
}

export function* handleFetchNextScreenData() {
  const nextScreenIndex: number = yield select(getNextScreenIndex)
  const nextScreenId: number = yield select(getScreenIdCurried(nextScreenIndex))

  const nextScreenRequestData: RequestData = {
    teladash_codigo: nextScreenId,
  }

  const { data }: Response = yield call(
    Server.put,
    DASHBOARD_ROUTES.GET_GRAFICOS,
    nextScreenRequestData,
  )

  if (!data.isSuccessful) return

  const firstData = data.getFirstData()
  const screenList = firstData?.LISTA_TELAS
  if (!screenList) return

  const [firstScreen] = screenList
  if (!firstScreen) return

  yield put(setScreenAtIndex(firstScreen, nextScreenIndex))
}

export function* handleFetchPreviousScreenData() {
  const previousScreenIndex: number = yield select(getPreviousScreenIndex)
  const previousScreenId: number = yield select(
    getScreenIdCurried(previousScreenIndex),
  )

  const previousScreenRequestData: RequestData = {
    teladash_codigo: previousScreenId,
  }

  const { data }: Response = yield call(
    Server.put,
    DASHBOARD_ROUTES.GET_GRAFICOS,
    previousScreenRequestData,
  )

  if (!data.isSuccessful) return

  const firstData = data.getFirstData()
  const screenList = firstData?.LISTA_TELAS
  if (!screenList) return

  const [firstScreen] = screenList
  if (!firstScreen) return

  yield put(setScreenAtIndex(firstScreen, previousScreenIndex))
}

export function* handleFetchAutoDashboardInitialData() {
  try {
    yield put(addLoading(AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY))
    yield call(handleFetchCurrentScreenData)
    yield call(handleFetchNextScreenData)
    yield call(handleFetchPreviousScreenData)
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY))
  }
}

export function* requestFetchAutoDashboardInitialDataWatcher() {
  yield takeLatest(
    AUTO_DASHBOARD_TYPES.REQUEST_FETCH_INITIAL_DATA,
    handleFetchAutoDashboardInitialData,
  )
}
