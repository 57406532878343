import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showSuccessToast } from 'src/store/ducks/success'
import {
  serverStructureFactory,
  AxiosServerResponse,
  ANALYSIS_ROUTES,
  Server,
} from 'src/services'

import { ANALYSIS, RequestCreateAnalysisAction } from './types'
import { getAnalysisList } from './selects'
import { setAnalysisList } from './actions'

type RequestData = Omit<UncDashboard.Analysis, 'analise_codigo'>

export function* requestCreateAnalysisWatcher() {
  yield takeLatest(ANALYSIS.REQUEST_CREATE_ANALYSIS, handleCreateAnalysis)
}

export function* handleCreateAnalysis(action: RequestCreateAnalysisAction) {
  try {
    yield put(addLoading(ANALYSIS.REQUEST_CREATE_ANALYSIS))

    const { payload } = action

    const requestData: RequestData = {
      tipoanalise_codigo: payload.tipoanalise_codigo,
      analise_descricao: payload.analise_descricao,
      analise_tipovendedor: payload.analise_tipovendedor,
      analise_empresas: payload.analise_empresas,
      analise_tiposdevenda: payload.analise_tiposdevenda,
    }

    const { data }: AxiosServerResponse<UncDashboard.Analysis> = yield call(
      Server.put,
      ANALYSIS_ROUTES.ANALISE,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'createAnalysis' }))
      const createdAnalysis = data.getFirstData()
      if (!createdAnalysis) return
      const analysis: UncDashboard.Analysis[] = yield select(getAnalysisList)
      yield put(setAnalysisList([...analysis, createdAnalysis]))

      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(ANALYSIS.REQUEST_CREATE_ANALYSIS))
  }
}
