import styled from 'styled-components'

import {
  AccentButton,
  Input,
  SmallDangerButton,
  CheckBox,
  SpinnerWithText,
} from 'src/components'

export const Container = styled.div``

export const Form = styled.form`
  background: ${(props) => props.theme.background};
  border-radius: 1rem;

  padding: 1.6rem;
`

export const StyledInput = styled(Input)`
  margin-bottom: 1.6rem;
`

export const HideFormButton = styled(SmallDangerButton)`
  width: auto;

  margin-left: auto;
  margin-bottom: 1.6rem;

  svg {
    margin-left: 1.6rem;
  }
`

export const SaveScreenButton = styled(AccentButton)``

export const StyledCheckBox = styled(CheckBox)`
  flex: 1 40%;
  min-width: 18rem;

  margin: 0.8rem;

  .label {
    text-transform: capitalize;
    color: ${(props) => props.theme.secondaryText};

    transition: color 0.2s ease-in-out;

    margin-left: 1.6rem;
  }

  &:hover {
    .label {
      color: ${(props) => props.theme.primaryText};
    }
  }
`

export const CompanyContent = styled.div`
  margin-bottom: 1.8rem;
`
export const CompanyLabel = styled.label`
  color: ${({ theme }) => theme.primaryText};
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;

  * {
    margin-right: 0.8rem;
  }

  .label-hint {
    color: ${({ theme }) => theme.secondaryText};
    font-size: 1.2rem;
    font-style: italic;
  }

  .required {
    color: ${({ theme }) => theme.warning};
    font-size: 1.3rem;
    font-style: italic;
  }
`
export const Company = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  border-radius: 1rem;
  border: 1px solid ${(props) => props.theme.border};

  padding: 0.8rem;
`
export const StyledSpinnerWithText = styled(SpinnerWithText)`
  margin: 2.4rem 0;
`
