export enum SALES_TYPE_TYPES {
  REQUEST_FETCH_SALES_TYPES = '@SALES_TYPE/REQUEST_FETCH_SALES_TYPES',
  SET_SALES_TYPES_LIST = '@SALES_TYPE/SET_SALES_TYPES_LIST',
}

export interface SalesTypeState {
  salesTypes: UncDashboard.SalesType[]
}

export interface RequestFetchSalesTypesAction {
  type: SALES_TYPE_TYPES.REQUEST_FETCH_SALES_TYPES
}

export interface SetSalesTypesListAction {
  type: SALES_TYPE_TYPES.SET_SALES_TYPES_LIST
  payload: {
    salesTypes: UncDashboard.SalesType[]
  }
}
