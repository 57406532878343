import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  setScreenList,
  setCurrentScreenIndex,
  requestFetchAutoDashboardInitialData,
} from 'src/store/ducks/autoDashboard'
import { useTypedSelector } from 'src/hooks'

export default () => {
  const dispatch = useDispatch()

  const summary = useTypedSelector(({ AutoDashboard }) => AutoDashboard.summary)

  useEffect(() => {
    if (summary) {
      dispatch(setScreenList([]))
      dispatch(setCurrentScreenIndex(0))
      dispatch(requestFetchAutoDashboardInitialData())
    }
  }, [dispatch, summary])
}
