import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Dashboard from 'src/pages/Dashboard'
import PageNotFound from 'src/pages/PageNotFound'
import CreateAnalysis from 'src/pages/CreateAnalysis'
import DashboardScreen from 'src/pages/DashboardScreen'
import AutomaticDashboard from 'src/pages/AutomaticDashboard'
import CreateDashboardType from 'src/pages/CreateDashboardType'

const MainContentRoutes: React.FC = () => {
  return (
    <Switch>
      <Route component={Dashboard} path="/" exact />
      <Route component={AutomaticDashboard} path="/automatic-dashboard" />

      <Route component={CreateAnalysis} path="/create-analysis" />
      <Route component={DashboardScreen} path="/create-dashboard-screen" />
      <Route component={CreateDashboardType} path="/create-dashboard-type" />

      <Route component={PageNotFound} />
    </Switch>
  )
}

export default MainContentRoutes
