import styled from 'styled-components'

import { DefaultScrollBar } from 'src/styles'

export const Content = styled.div`
  ${DefaultScrollBar};
  overflow-x: auto;

  width: 100%;
`

export const Table = styled.table`
  width: 100%;

  border-collapse: collapse;

  &,
  td,
  th {
    border: 1px solid ${(props) => props.theme.border};
  }

  td,
  th {
    padding: 0.4rem 0.6rem;
    text-align: center;
  }

  th {
    background: ${(props) => props.theme.snowLight};
  }

  td.weekend {
    background: ${(props) => props.theme.snow};
  }

  td.holiday {
    background: ${(props) => props.theme.contrastSecondaryText};
  }

  td.current_day {
    background: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.background};
  }
`
