import { Reducer } from 'redux'

import { COMPANY_TYPES, CompanyState } from './types'

export const initialState: CompanyState = {
  companyList: [],
}

export const CompanyReducer: Reducer<CompanyState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case COMPANY_TYPES.SET_COMPANY_LIST: {
      return {
        ...state,
        companyList: payload.companyList,
      }
    }

    default:
      return state
  }
}
