export enum COMPANY_TYPES {
  REQUEST_FETCH_COMPANIES = '@COMPANY/REQUEST_FETCH_COMPANIES',
  SET_COMPANY_LIST = '@COMPANY/SET_COMPANY_LIST',
}

export interface CompanyState {
  companyList: UncDashboard.Company[]
}

export interface RequestFetchCompaniesAction {
  type: COMPANY_TYPES.REQUEST_FETCH_COMPANIES
}

export interface SetCompanyListAction {
  type: COMPANY_TYPES.SET_COMPANY_LIST
  payload: {
    companyList: UncDashboard.Company[]
  }
}
