import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  serverStructureFactory,
  AxiosServerResponse,
  DASHBOARD_ROUTES,
  Server,
} from 'src/services'

import { DASHBOARD_TYPE_TYPES, RequestUpdateDashboardTypeAction } from './types'
import { getDashboardTypeList } from './selects'
import { setDashboardTypesList } from './actions'

export function* requestUpdateDashboardTypeWatcher() {
  yield takeLatest(
    DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE,
    handleUpdateDashboardType,
  )
}

export function* handleUpdateDashboardType(
  action: RequestUpdateDashboardTypeAction,
) {
  try {
    yield put(addLoading(DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE))

    const { payload } = action

    const requestData: UncDashboard.Dashboard = {
      dash_codigo: payload.id,
      dash_descricao: payload.description,
      dash_tipo: payload.dashboardCategory,
      dash_automatico: payload.isAutomatic ? 'S' : 'N',
      dash_tempotelas: Number(payload.duration),
      dash_situacao: payload.situation,
    }

    const { data }: AxiosServerResponse<UncDashboard.Dashboard> = yield call(
      Server.post,
      DASHBOARD_ROUTES.PATCH_DASHBOARD,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'updateDashboardType' }))
      const updatedDashboardType = data.getFirstData()
      if (!updatedDashboardType) return
      const dashboardTypes: UncDashboard.Dashboard[] = yield select(
        getDashboardTypeList,
      )

      const updatedDashboardTypeList = dashboardTypes.map((dashboardType) => {
        if (dashboardType.dash_codigo === updatedDashboardType.dash_codigo) {
          return updatedDashboardType
        }

        return dashboardType
      })

      yield put(setDashboardTypesList(updatedDashboardTypeList))

      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE))
  }
}
