import React from 'react'

import { Container, Header, Title, Data } from './styles'

export interface CardProps {
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  iconComponent?: React.ReactNode
  title: string
  data: React.ReactText
  footerComponent?: React.ReactNode
}

export const Card: React.FC<CardProps> = (props) => {
  const {
    className,
    style,
    onClick,
    iconComponent,
    title,
    data,
    footerComponent,
  } = props

  return (
    <Container className={className} style={style} onClick={onClick}>
      <Header>
        {iconComponent}
        <Title hasIcon={!!iconComponent}>{title}</Title>
      </Header>

      <Data>{data}</Data>

      {footerComponent}
    </Container>
  )
}
