import {
  ANALYSIS_TYPE_TYPES,
  RequestFetchAnalysisTypesAction,
  SetAnalysisTypesListAction,
  RequestCreateAnalysisTypesAction,
  RequestUpdateAnalysisTypesAction,
} from './types'

export const requestFetchAnalysisTypes = (): RequestFetchAnalysisTypesAction => ({
  type: ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES,
})

export const setAnalysisTypesList = (
  analysisTypes: UncDashboard.AnalysisType[],
): SetAnalysisTypesListAction => ({
  type: ANALYSIS_TYPE_TYPES.SET_ANALYSIS_TYPES_LIST,
  payload: {
    analysisTypes,
  },
})

export const RequestCreateAnalysisTypes = (
  payload: RequestCreateAnalysisTypesAction['payload'],
): RequestCreateAnalysisTypesAction => ({
  type: ANALYSIS_TYPE_TYPES.REQUEST_CREATE_ANALYSIS_TYPES,
  payload,
})

export const RequestUpdateAnalysisTypes = (
  payload: RequestUpdateAnalysisTypesAction['payload'],
): RequestUpdateAnalysisTypesAction => ({
  type: ANALYSIS_TYPE_TYPES.REQUEST_UPDATE_ANALYSIS_TYPES,
  payload,
})
