import styled from 'styled-components'

import { Input } from 'src/components'

export const Container = styled.div`
  padding: 1.6rem;
  margin: 0 0.8rem 1.6rem 0.8rem;

  background: ${(props) => props.theme.background};

  border-radius: 1rem;
`

export const Label = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  color: ${(props) => props.theme.secondaryText};

  margin-bottom: 1.6rem;
`

export const StyledInput = styled(Input)``
