import { call, put, select, takeEvery } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { setScreenAtIndex } from './actions'
import { AUTO_DASHBOARD_TYPES } from './types'
import { getCurrentScreenIndex, getSummaryScreenList } from './selects'

type RequestData = Pick<UncDashboard.DashboardScreen, 'teladash_codigo'>
type ScreenList = UncDashboard.ComposedTypes.DashboardDataScreen[]

type Response = AxiosServerResponse<UncDashboard.ComposedTypes.DashboardData>

export function* requestFetchNextScreenWatcher() {
  yield takeEvery(AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN, handleFetchNextScreen)
}

export function* handleFetchNextScreen() {
  try {
    yield put(addLoading(AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN))

    const currentScreenIndex: number = yield select(getCurrentScreenIndex)
    const screenList: ScreenList = yield select(getSummaryScreenList)

    const possibleNextIndex = currentScreenIndex + 1
    const isIndexOutOfBounds = possibleNextIndex === screenList.length
    const nextScreenIndex = isIndexOutOfBounds ? 0 : possibleNextIndex

    const screenId = screenList[nextScreenIndex].teladash_codigo

    const requestData: RequestData = {
      teladash_codigo: screenId,
    }

    const { data }: Response = yield call(
      Server.put,
      DASHBOARD_ROUTES.GET_GRAFICOS,
      requestData,
    )

    if (data.isSuccessful) {
      const firstData = data.getFirstData()
      const screenList = firstData?.LISTA_TELAS

      if (screenList) {
        const [firstScreen] = screenList
        if (firstScreen) {
          yield put(setScreenAtIndex(firstScreen, nextScreenIndex))
        }
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN))
  }
}
