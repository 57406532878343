export enum ANALYSIS_TYPE_TYPES {
  REQUEST_FETCH_ANALYSIS_TYPES = '@ANALYSIS_TYPE/REQUEST_FETCH_ANALYSIS_TYPES',
  SET_ANALYSIS_TYPES_LIST = '@ANALYSIS_TYPE/SET_ANALYSIS_TYPES_LIST',
  REQUEST_CREATE_ANALYSIS_TYPES = '@ANALYSIS_TYPE/REQUEST_CREATE_ANALYSIS_TYPES',
  REQUEST_UPDATE_ANALYSIS_TYPES = '@ANALYSIS_TYPE/REQUEST_UPDATE_ANALYSIS_TYPES',
  REQUEST_REMOVE_ANALYSIS_TYPES = '@ANALYSIS_TYPE/REQUEST_REMOVE_ANALYSIS_TYPES',
}

export interface AnalysisTypeState {
  analysisTypes: UncDashboard.AnalysisType[]
}

export interface RequestFetchAnalysisTypesAction {
  type: ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES
}

export interface SetAnalysisTypesListAction {
  type: ANALYSIS_TYPE_TYPES.SET_ANALYSIS_TYPES_LIST
  payload: {
    analysisTypes: UncDashboard.AnalysisType[]
  }
}

export interface RequestCreateAnalysisTypesAction {
  type: ANALYSIS_TYPE_TYPES.REQUEST_CREATE_ANALYSIS_TYPES
  payload: {
    tipoanalise_codigo: number
    tipoanalise_tipo?: UncDashboard.UnionTypes.AnalysisType
    tipoanalise_descricao: string
    successCallback?: () => void
  }
}

export interface RequestUpdateAnalysisTypesAction {
  type: ANALYSIS_TYPE_TYPES.REQUEST_UPDATE_ANALYSIS_TYPES
  payload: {
    tipoanalise_codigo: number
    tipoanalise_tipo?: UncDashboard.UnionTypes.AnalysisType
    tipoanalise_descricao: string
    successCallback?: () => void
  }
}

export interface RequestRemoveAnalysisTypesAction {
  type: ANALYSIS_TYPE_TYPES.REQUEST_REMOVE_ANALYSIS_TYPES
  payload: {
    id: number
  }
}
