import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiBarChart2, FiPlusCircle } from 'react-icons/fi'

import { useToggleFunctions, useSetBrowserTabTitle } from 'src/hooks'
import { requestFetchCompanies, setCompanyList } from 'src/store/ducks/company'
import {
  requestFetchSalesTypes,
  setSalesTypesList,
} from 'src/store/ducks/salesType'

import DashboardAnalysisList from './AnalysisList'
import AnalysisForm from './AnalysisForm'
import { Container, StyledPageTitle, CreateScreenButton } from './styles'

const CreateAnalysis: React.FC = () => {
  useSetBrowserTabTitle('createAnalysis')

  const { t } = useTranslation('CreateAnalysis')

  const dispatch = useDispatch()

  const [
    editingAnalysis,
    setEditingAnalysis,
  ] = useState<UncDashboard.Analysis | null>(null)

  const [
    isShowingScreenForm,
    handleShowScreenForm,
    handleHideScreenForm,
  ] = useToggleFunctions()

  useEffect(() => {
    if (editingAnalysis) handleShowScreenForm()
    else handleHideScreenForm()
  }, [editingAnalysis, handleShowScreenForm, handleHideScreenForm])

  useEffect(() => {
    dispatch(requestFetchCompanies())
    dispatch(requestFetchSalesTypes())

    return () => {
      dispatch(setCompanyList([]))
      dispatch(setSalesTypesList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <StyledPageTitle
        iconComponent={<FiBarChart2 />}
        title={t('title')}
        subtitle={t('subtitle')}
      />

      {isShowingScreenForm ? (
        <AnalysisForm
          handleHideScreenForm={handleHideScreenForm}
          editingAnalysis={editingAnalysis}
          setEditingAnalysis={setEditingAnalysis}
        />
      ) : (
        <CreateScreenButton onClick={handleShowScreenForm}>
          <span>{t('createScreenButton')}</span>
          <FiPlusCircle />
        </CreateScreenButton>
      )}

      <DashboardAnalysisList
        editingAnalysis={editingAnalysis}
        setEditingAnalysis={setEditingAnalysis}
      />
    </Container>
  )
}

export default CreateAnalysis
