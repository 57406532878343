import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccess } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, LOGIN_ROUTES, Server } from 'src/services'

import { PASSWORD_TYPES, RequestForgotPasswordAction } from './types'

export function* requestForgotPasswordWatcher() {
  yield takeLatest(
    PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD,
    handleSendForgotPasswordEmail,
  )
}

export function* handleSendForgotPasswordEmail(
  action: RequestForgotPasswordAction,
) {
  try {
    yield put(addLoading(PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD))
    const { email } = action.payload

    const params = [LOGIN_ROUTES.RESETAR_SENHA, email, '0001']

    const { data }: AxiosServerResponse = yield call(
      Server.get,
      params.join('/'),
    )

    if (data.isSuccessful) {
      yield put(showSuccess(PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD))
  }
}
