import { all } from 'redux-saga/effects'

import {
  requestAuthenticateWatcher,
  requestLogoutWatcher,
  requestCheckAuthWatcher,
  setHeadersWatcher,
  clearHeadersWatcher,
} from 'src/store/ducks/auth'
import {
  requestForgotPasswordWatcher,
  requestResetPasswordWatcher,
} from 'src/store/ducks/password'
import {
  requestFetchDashboardScreensWatcher,
  requestSaveSelectedDashboardScreenWatcher,
  requestFetchFullDashboardScreensWatcher,
  requestGetNextSequenceWatcher,
  requestCreateDashboardScreenWatcher,
  requestRemoveDashboardScreenWatcher,
  requestUpdateDashboardScreenWatcher,
} from 'src/store/ducks/dashboardScreen'
import { requestGetDashboardDataWatcher } from 'src/store/ducks/dashboard'
import {
  requestFetchSummaryWatcher,
  requestFetchNextScreenWatcher,
  requestFetchPreviousScreenWatcher,
  requestFetchAutoDashboardInitialDataWatcher,
} from 'src/store/ducks/autoDashboard'
import { requestFetchAnalysisTypesWatcher } from 'src/store/ducks/analysisType'
import {
  requestFetchDashboardTypesWatcher,
  requestCreateDashboardTypeWatcher,
  requestUpdateDashboardTypeWatcher,
  removeDashboardTypeWatcher,
  requestToggleDashboardTypeSituationWatcher,
} from 'src/store/ducks/dashboardType'
import { requestFetchCompaniesWatcher } from 'src/store/ducks/company'
import { requestFetchSalesTypesWatcher } from 'src/store/ducks/salesType'
import {
  requestFetchAnalysisWatcher,
  requestRemoveAnalysisWatcher,
  requestCreateAnalysisWatcher,
  requestUpdateAnalysisWatcher,
} from 'src/store/ducks/analysis'

export default function* rootSaga() {
  yield all([
    setHeadersWatcher(),
    clearHeadersWatcher(),
    requestLogoutWatcher(),
    requestCheckAuthWatcher(),
    requestAuthenticateWatcher(),

    requestResetPasswordWatcher(),
    requestForgotPasswordWatcher(),

    requestGetNextSequenceWatcher(),
    requestFetchDashboardScreensWatcher(),
    requestRemoveDashboardScreenWatcher(),
    requestCreateDashboardScreenWatcher(),
    requestUpdateDashboardScreenWatcher(),
    requestFetchFullDashboardScreensWatcher(),
    requestSaveSelectedDashboardScreenWatcher(),

    requestGetDashboardDataWatcher(),

    requestFetchSummaryWatcher(),
    requestFetchNextScreenWatcher(),
    requestFetchPreviousScreenWatcher(),
    requestFetchAutoDashboardInitialDataWatcher(),

    requestFetchAnalysisTypesWatcher(),

    removeDashboardTypeWatcher(),
    requestFetchDashboardTypesWatcher(),
    requestCreateDashboardTypeWatcher(),
    requestUpdateDashboardTypeWatcher(),
    requestToggleDashboardTypeSituationWatcher(),

    requestFetchCompaniesWatcher(),

    requestFetchSalesTypesWatcher(),

    requestFetchAnalysisWatcher(),
    requestRemoveAnalysisWatcher(),
    requestCreateAnalysisWatcher(),
    requestUpdateAnalysisWatcher(),
  ])
}
