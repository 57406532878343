export enum PASSWORD_TYPES {
  REQUEST_FORGOT_PASSWORD = '@PASSWORD/REQUEST_FORGOT_PASSWORD',
  REQUEST_RESET_PASSWORD = '@PASSWORD/REQUEST_RESET_PASSWORD',
}

export interface RequestForgotPasswordAction {
  type: PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD
  payload: {
    email: string
  }
}

export interface RequestResetPasswordAction {
  type: PASSWORD_TYPES.REQUEST_RESET_PASSWORD
  payload: {
    hash: string
    password: string
    confirmPassword: string
    successCallback?: () => void
  }
}
