import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showSuccessToast } from 'src/store/ducks/success'
import {
  serverStructureFactory,
  AxiosServerResponse,
  ANALYSIS_ROUTES,
  Server,
} from 'src/services'

import { ANALYSIS, RequestUpdateAnalysisAction } from './types'
// import { getAnalysisList } from './selects'
import { requestFetchAnalysis } from './actions'

export function* requestUpdateAnalysisWatcher() {
  yield takeLatest(ANALYSIS.REQUEST_UPDATE_ANALYSIS, handleUpdateAnalysis)
}

export function* handleUpdateAnalysis(action: RequestUpdateAnalysisAction) {
  try {
    yield put(addLoading(ANALYSIS.REQUEST_UPDATE_ANALYSIS))

    const { payload } = action

    const requestData: UncDashboard.Analysis = {
      analise_codigo: payload.analise_codigo,
      analise_descricao: payload.analise_descricao,
      tipoanalise_codigo: payload.tipoanalise_codigo,
      analise_empresas: payload.analise_empresas,
      analise_tipovendedor: payload.analise_tipovendedor,
      analise_tiposdevenda: payload.analise_tiposdevenda,
    }

    const { data }: AxiosServerResponse<UncDashboard.Analysis> = yield call(
      Server.post,
      ANALYSIS_ROUTES.ANALISE,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'updateAnalysis' }))
      const updateAnalysisType = data.getFirstData()
      if (!updateAnalysisType) return
      // const analysisTypes: UncDashboard.Analysis[] = yield select(
      //   getAnalysisList,
      // )

      // const updatedAnalysisTypeList = analysisTypes.map((analysisTypes) => {
      //   if (analysisTypes.analise_codigo === updateAnalysisType.analise_codigo)
      //     return updateAnalysisType

      //   return analysisTypes
      // })

      // yield put(setAnalysisList(updatedAnalysisTypeList))

      yield put(requestFetchAnalysis())

      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(ANALYSIS.REQUEST_UPDATE_ANALYSIS))
  }
}
