import React from 'react'

import { useSetBrowserTabTitle } from 'src/hooks'
// import { PageAccessVerifier } from 'src/components'

import Controls from './Controls'
import useFetchSummary from './useFetchSummary'
import DataVisualization from './DataVisualization'
import ValidationWrapper from './ValidationWrapper'
import useResetWhenSetSummary from './useResetWhenSetSummary'
import Settings from './Settings'
import { Container } from './styles'

const AutomaticDashboard: React.FC = () => {
  useSetBrowserTabTitle('automaticDashboard')

  useResetWhenSetSummary()
  useFetchSummary()

  return (
    // <PageAccessVerifier page={157}>
    <Container>
      <Settings />
      <ValidationWrapper>
        <Controls />
        <DataVisualization />
      </ValidationWrapper>
    </Container>
    // </PageAccessVerifier>
  )
}

export default AutomaticDashboard
