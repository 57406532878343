import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  requestFetchDashboardScreens,
  setDashboardScreenList,
} from 'src/store/ducks/dashboardScreen'

export default () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestFetchDashboardScreens())
    return () => {
      dispatch(setDashboardScreenList([]))
    }
  }, [dispatch])
}
