import styled from 'styled-components'

import { RoundIconButton } from 'src/components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding: 0.4rem 2.4rem;
  margin: 0.8rem;

  background: ${(props) => props.theme.background};

  border-radius: 1rem;
  overflow: hidden;
`

export const TimerText = styled.div`
  text-align: center;

  margin-bottom: 0.2rem;
`

export const ControlButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 1.8rem;
`

export const ControlButton = styled(RoundIconButton)`
  margin: 0 0.4rem;
`
