import styled, { css } from 'styled-components'

const TextWithIcons = css`
  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

type SituationTextProps = {
  isActive: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 0.8rem 1.6rem;

  background-color: ${(props) => props.theme.background};

  border: solid 0.2rem ${(props) => props.theme.border};
  border-radius: 1rem;

  overflow: hidden;

  transition: border-color 0.2s ease-in-out;
`
export const Title = styled.div`
  ${TextWithIcons};

  font-size: 1.8rem;
  font-weight: 900;
`

export const Text = styled.div`
  ${TextWithIcons};

  color: ${(props) => props.theme.secondaryText};
`
export const MoreDetails = styled.div`
  ${TextWithIcons};
  margin-top: 0.8rem;

  font-size: 1.9rem;
  color: ${(props) => props.theme.secondaryText};
`
export const Children = styled.div`
  margin-top: 0.8rem;
`

export const Content = styled.div`
  flex: 1;
  border-radius: 1rem;
`

export const Actions = styled.div`
  display: flex;

  align-items: center;
`
export const SituationText = styled.div<SituationTextProps>`
  ${TextWithIcons};

  color: ${(props) =>
    props.isActive ? props.theme.success : props.theme.danger};
`
