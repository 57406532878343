import React from 'react'
import { FiPieChart } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { Spinner, SpinnerWithText } from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { DASHBOARD_TYPES } from 'src/store/ducks/dashboard'

import { SpinnerContainer, DashboardErrorMessage, Container } from './styles'

const ValidationWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation('Dashboard')

  const isLoading = useIsLoading(DASHBOARD_TYPES.REQUEST_GET_DATA)

  const shouldSelectScreen = useTypedSelector(
    ({ DashboardScreen }) => !DashboardScreen.selectedScreen,
  )

  const hasNoDataToShow = useTypedSelector(
    ({ Dashboard }) => !Dashboard.dashboardData,
  )

  if (isLoading) {
    return (
      <Container>
        <SpinnerContainer>
          <SpinnerWithText hasSpinnerOnTheLeft>
            <Spinner />
            <span>{t('loadingText')}</span>
          </SpinnerWithText>
        </SpinnerContainer>
      </Container>
    )
  }

  if (shouldSelectScreen || hasNoDataToShow) {
    return (
      <Container>
        <DashboardErrorMessage
          iconComponent={<FiPieChart />}
          message={t(
            shouldSelectScreen ? 'noDashboardScreenSelected' : 'noDataToShow',
          )}
        />
      </Container>
    )
  }

  return <>{children}</>
}

export default ValidationWrapper
