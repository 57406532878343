import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { DASHBOARD_TYPES, RequestGetDashboardDataAction } from './types'
import { setDashboardData } from './actions'

type RequestData = Pick<UncDashboard.DashboardScreen, 'teladash_codigo'>

export function* requestGetDashboardDataWatcher() {
  yield takeLatest(DASHBOARD_TYPES.REQUEST_GET_DATA, handleGetDashboardData)
}

export function* handleGetDashboardData(action: RequestGetDashboardDataAction) {
  try {
    yield put(addLoading(DASHBOARD_TYPES.REQUEST_GET_DATA))
    const { dashboardScreenId } = action.payload

    const requestData: RequestData = {
      teladash_codigo: dashboardScreenId,
    }

    const {
      data,
    }: AxiosServerResponse<UncDashboard.ComposedTypes.DashboardData> =
      yield call(Server.put, DASHBOARD_ROUTES.GET_GRAFICOS, requestData)

    if (data.isSuccessful) {
      const dashboardData = data.getFirstData()

      yield put(setDashboardData(dashboardData || undefined))
    } else {
      yield put(setDashboardData(undefined))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_TYPES.REQUEST_GET_DATA))
  }
}
