import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { AUTO_DASHBOARD_TYPES, RequestFetchSummaryAction } from './types'
import { setSummary } from './actions'

export function* requestFetchSummaryWatcher() {
  yield takeLatest(
    AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY,
    handleFetchSummary,
  )
}

export function* handleFetchSummary(action: RequestFetchSummaryAction) {
  try {
    yield put(addLoading(AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY))
    const { dashboardId } = action.payload
    const {
      data,
    }: AxiosServerResponse<UncDashboard.ComposedTypes.DashboardData> =
      yield call(
        Server.put,
        DASHBOARD_ROUTES.GET_GRAFICOS_RESUMIDO,
        dashboardId,
      )

    if (data.isSuccessful) {
      const summary = data.getFirstData()
      yield put(setSummary(summary || undefined))
    } else {
      yield put(setSummary(undefined))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY))
  }
}
