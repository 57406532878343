import styled from 'styled-components'

import { EmptyMessage } from 'src/components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const SpinnerContainer = styled.div`
  margin: 0.8rem;
`

export const NoDashboardData = styled(EmptyMessage)`
  padding: 0 0.8rem;
`
