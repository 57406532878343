import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiList, FiMoreHorizontal, FiTrash } from 'react-icons/fi'

import {
  DefaultTooltip,
  ConfirmModal,
  DangerRoundIconButton,
  RoundIconButton,
  Spinner,
  SimpleModal,
} from 'src/components'
import {
  ANALYSIS,
  setAnalysisList,
  requestFetchAnalysis,
  requestRemoveAnalysis,
} from 'src/store/ducks/analysis'
import { useIsLoading, useTypedSelector, useRebuildTooltips } from 'src/hooks'

import {
  StyledAnalysisItem,
  List,
  Actions,
  StyledSpinnerWithText,
  StyledAnalysisModal,
} from './styles'

interface DashboardAnalysisListProps {
  setEditingAnalysis: React.Dispatch<
    React.SetStateAction<UncDashboard.Analysis | null>
  >
  editingAnalysis: UncDashboard.Analysis | null
}

type SalesTypeObject = {
  PV: boolean
  componente?: string
}

type CompanyTypeObject = {
  PV: boolean
  componente?: string
}

const DashboardAnalysisList: React.FC<DashboardAnalysisListProps> = ({
  editingAnalysis,
  setEditingAnalysis,
}) => {
  useRebuildTooltips()

  const { t } = useTranslation([
    'DashboardAnalysis',
    'DashboardTypes',
    'SellerTypes',
    'AnalysisType',
    'Glossary',
  ])

  const dispatch = useDispatch()

  const analysisList = useTypedSelector(({ AnalysisReducer }) => {
    return AnalysisReducer.analysis
  })

  const isLoading = useIsLoading(ANALYSIS.REQUEST_FETCH_ANALYSIS)

  const [analysisId, setAnalysisId] = useState<number | null>(null)
  const [analysisModalId, setAnalysisModalId] = useState<number | null>(null)

  const handleDeleteAnalysis = () => {
    if (!analysisId) return
    dispatch(
      requestRemoveAnalysis({
        id: analysisId,
      }),
    )
  }

  useEffect(() => {
    dispatch(requestFetchAnalysis())
    return () => {
      dispatch(setAnalysisList([]))
    }
  }, [dispatch])

  return (
    <>
      <ConfirmModal
        message={t('AnalysisType:deleteModalMessage')}
        isShowing={!!analysisId}
        confirmButtonText={t('Glossary:delete')}
        title={t('AnalysisType:deleteModalTitle')}
        confirmButtonType="danger"
        cancelButtonText={t('Glossary:cancel')}
        handleHideModal={() => setAnalysisId(null)}
        iconComponent={<FiTrash size="2.4rem" />}
        onConfirm={handleDeleteAnalysis}
      />

      <SimpleModal
        title={t('Glossary:moreDetails')}
        isShowing={!!analysisModalId}
        handleHideModal={() => setAnalysisModalId(null)}
        iconComponent={<FiList size="2.4rem" />}
      >
        {analysisList.map((dashboardScreen) => {
          const salesTypesObject: SalesTypeObject = dashboardScreen.analise_tiposdevenda
            ? JSON.parse(dashboardScreen.analise_tiposdevenda)
            : {}

          delete salesTypesObject.componente
          const salesTypesCodes = Object.keys(salesTypesObject).join(', ')

          const companyTypesObject: CompanyTypeObject = dashboardScreen.analise_empresas
            ? JSON.parse(dashboardScreen.analise_empresas)
            : {}

          delete companyTypesObject.componente

          const companyTypesCodes = Object.keys(companyTypesObject).join(', ')

          const commaSeparatedCompanies = companyTypesCodes
            ? t('analysisCompanies', {
                companies: companyTypesCodes,
              })
            : undefined

          const commaSeparatedSalesTypes = salesTypesCodes
            ? t('allAnalysisSalesTypes', {
                salesTypes: salesTypesCodes,
              })
            : undefined
          return (
            dashboardScreen.analise_codigo === analysisModalId && (
              <StyledAnalysisModal
                name={dashboardScreen.analise_descricao}
                analysisType={String(dashboardScreen.tipoanalise_codigo)}
                salesTypes={commaSeparatedSalesTypes}
                companies={commaSeparatedCompanies}
                sellerType={t('analysisSellerType', {
                  sellerType: t(
                    `SellerTypes:${dashboardScreen.analise_tipovendedor}`,
                  ),
                })}
              />
            )
          )
        })}
      </SimpleModal>

      <DefaultTooltip />

      {!isLoading ? (
        <List>
          {analysisList.map((analysisItem) => {
            const {
              analise_codigo,
              analise_descricao,
              analise_tipovendedor,
              analise_empresas,
              tipoanalise_codigo,
              analise_tiposdevenda,
            } = analysisItem

            const isSelected =
              analise_codigo === editingAnalysis?.analise_codigo

            const salesTypesObject: SalesTypeObject = analise_tiposdevenda
              ? JSON.parse(analise_tiposdevenda)
              : {}

            delete salesTypesObject.componente
            const salesTypesCodes = Object.keys(salesTypesObject)
              .slice(0, 3)
              .join(', ')

            const companyTypesObject: CompanyTypeObject = analise_empresas
              ? JSON.parse(analise_empresas)
              : {}

            delete companyTypesObject.componente

            const companyTypesCodes = Object.keys(companyTypesObject).join(', ')

            const commaSeparatedCompanies = companyTypesCodes
              ? t('analysisCompanies', {
                  companies: companyTypesCodes,
                })
              : undefined

            const commaSeparatedSalesTypes = salesTypesCodes
              ? t('analysisSalesTypes', {
                  salesTypes: salesTypesCodes,
                  count: Object.keys(salesTypesObject).slice(3).length,
                })
              : undefined

            const handleSelectThisItem = () => {
              setEditingAnalysis(isSelected ? null : analysisItem)
            }

            const handleDeleteAnalysis = (e: React.MouseEvent) => {
              e.stopPropagation()
              setAnalysisId(analysisItem.analise_codigo)
            }

            const handleShowAnalysis = (e: React.MouseEvent) => {
              e.stopPropagation()
              setAnalysisModalId(analysisItem.analise_codigo)
            }

            return (
              <StyledAnalysisItem
                key={analise_codigo}
                isSelected={isSelected}
                name={analise_descricao}
                analysisType={String(tipoanalise_codigo)}
                onClick={handleSelectThisItem}
                salesTypes={commaSeparatedSalesTypes}
                companies={commaSeparatedCompanies}
                sellerType={t('analysisSellerType', {
                  sellerType: t(`SellerTypes:${analise_tipovendedor}`),
                })}
                showMoreIcon={
                  <RoundIconButton
                    data-tip={t('Glossary:moreDetails')}
                    onClick={handleShowAnalysis}
                  >
                    <FiMoreHorizontal />
                  </RoundIconButton>
                }
                rightSideComponent={
                  <Actions>
                    <DangerRoundIconButton
                      data-tip={t('Glossary:delete')}
                      onClick={handleDeleteAnalysis}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  </Actions>
                }
              />
            )
          })}
        </List>
      ) : (
        <StyledSpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('Glossary:loading')}</span>
        </StyledSpinnerWithText>
      )}
    </>
  )
}

export default DashboardAnalysisList
