import {
  ANALYSIS,
  RequestFetchAnalysisAction,
  SetAnalysisListAction,
  RequestCreateAnalysisAction,
  RequestUpdateAnalysisAction,
  RequestRemoveAnalysisAction,
} from './types'

export const requestFetchAnalysis = (): RequestFetchAnalysisAction => ({
  type: ANALYSIS.REQUEST_FETCH_ANALYSIS,
})

export const setAnalysisList = (
  analysis: UncDashboard.Analysis[],
): SetAnalysisListAction => ({
  type: ANALYSIS.SET_ANALYSIS_LIST,
  payload: {
    analysis,
  },
})

export const requestCreateAnalysis = (
  payload: RequestCreateAnalysisAction['payload'],
): RequestCreateAnalysisAction => ({
  type: ANALYSIS.REQUEST_CREATE_ANALYSIS,
  payload,
})

export const requestUpdateAnalysis = (
  payload: RequestUpdateAnalysisAction['payload'],
): RequestUpdateAnalysisAction => ({
  type: ANALYSIS.REQUEST_UPDATE_ANALYSIS,
  payload,
})

export const requestRemoveAnalysis = (
  payload: RequestRemoveAnalysisAction['payload'],
): RequestRemoveAnalysisAction => ({
  type: ANALYSIS.REQUEST_REMOVE_ANALYSIS,
  payload,
})
