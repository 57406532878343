import React, { useState } from 'react'
import {
  FiAlignCenter,
  FiArrowDown,
  FiArrowRight,
  FiFileText,
  FiPieChart,
  FiTag,
} from 'react-icons/fi'

import {
  Description,
  Container,
  Children,
  Actions,
  Content,
  ShowAnalysis,
  Title,
  Text,
  Type,
} from './styles'

export interface DashboardScreenItemProps {
  className?: string
  style?: React.CSSProperties
  title?: string
  analysisShow?: string
  description?: string
  type?: string
  isActive?: boolean
  sequence?: string
  isSelected?: boolean
  isShowAnalysis?: boolean
  rightSideComponent?: React.ReactNode
  moreDetails?: React.ReactNode
  children?: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
}

export const DashboardScreenItem: React.FC<DashboardScreenItemProps> = (
  props,
) => {
  const {
    type,
    style,
    title,
    onClick,
    children,
    sequence,
    className,
    description,

    analysisShow,
    isSelected = false,
    isShowAnalysis = false,
    rightSideComponent,
  } = props

  const [showAnalysis, setShowAnalysis] = useState(false)

  return (
    <Container
      onClick={onClick}
      className={className}
      style={style}
      isSelected={isSelected}
      isShowAnalysis={isShowAnalysis}
    >
      <Content>
        <Title>
          <FiFileText />
          <span>{title}</span>
        </Title>

        <Text>
          <FiTag />
          <span>{sequence}</span>
        </Text>

        <ShowAnalysis
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            setShowAnalysis(!showAnalysis)
          }}
        >
          {showAnalysis ? <FiArrowDown /> : <FiArrowRight />}
          <span>{analysisShow}</span>
        </ShowAnalysis>

        {description ? (
          <Description>
            <FiAlignCenter />
            {description}
          </Description>
        ) : null}

        {type ? (
          <Type>
            <FiPieChart />
            {type}
          </Type>
        ) : null}

        {showAnalysis ? <Children> {children} </Children> : null}
      </Content>

      <Actions>{rightSideComponent}</Actions>
    </Container>
  )
}
