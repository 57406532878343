import {
  DASHBOARD_TYPES,
  RequestGetDashboardDataAction,
  SetDashboardDataAction,
} from './types'

export const requestGetDashboardData = (
  dashboardScreenId: number,
): RequestGetDashboardDataAction => ({
  type: DASHBOARD_TYPES.REQUEST_GET_DATA,
  payload: {
    dashboardScreenId,
  },
})

export const setDashboardData = (
  dashboardData?: UncDashboard.ComposedTypes.DashboardData,
): SetDashboardDataAction => ({
  type: DASHBOARD_TYPES.SET_DATA,
  payload: {
    dashboardData,
  },
})
