import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  serverStructureFactory,
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'

import {
  DASHBOARD_SCREEN_TYPES,
  RequestUpdateDashboardScreenAction,
} from './types'
// import { getDashboardScreenList } from './selects'
import { requestFetchFullDashboardScreen } from './actions'

type RequestData = Pick<
  UncDashboard.ComposedTypes.FullDashboardScreen,
  | 'dash_codigo'
  | 'dashtelas_seq'
  | 'teladash_descricao'
  | 'dashtelas_codigo'
  | 'TELADASH_ANALISE'
  | 'teladash_codigo'
>

export function* requestUpdateDashboardScreenWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE,
    handleUpdateDashboardScreen,
  )
}

export function* handleUpdateDashboardScreen(
  action: RequestUpdateDashboardScreenAction,
) {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE))

    const { payload } = action

    const requestData: RequestData = {
      dash_codigo: payload.dash_codigo,
      dashtelas_seq: payload.dashtelas_seq,
      teladash_codigo: payload.teladash_codigo,
      dashtelas_codigo: payload.dashtelas_codigo,
      TELADASH_ANALISE: payload.teladash_analise,
      teladash_descricao: payload.teladash_descricao,
    }

    const { data }: AxiosServerResponse<any, RequestData> = yield call(
      Server.post,
      DASHBOARD_SCREEN_ROUTES.PATCH_DASHBOARD_TELAS,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'updateDashboardScreen' }))
      const updatedDashboardScreen = data.getFirstData()
      if (!updatedDashboardScreen) return

      // const dashboardScreens: UncDashboard.ComposedTypes.GetDashboardScreen[] = yield select(
      //   getDashboardScreenList,
      // )

      // const dashboardList = dashboardScreens.map((Dashboard) => {
      //   if (Dashboard.dash_codigo === updatedDashboardScreen.dash_codigo)
      //     return updatedDashboardScreen
      //   return Dashboard
      // })

      yield put(requestFetchFullDashboardScreen())

      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE))
  }
}
