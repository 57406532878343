/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FiAlignCenter,
  FiCheck,
  FiCheckCircle,
  FiPieChart,
  FiTrash,
  FiHome,
  FiShoppingCart,
  FiUsers,
} from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
  Spinner,
  WhiteSpinner,
} from 'src/components'
import {
  ANALYSIS_TYPE_TYPES,
  requestFetchAnalysisTypes,
  setAnalysisTypesList,
} from 'src/store/ducks/analysisType'
import { useIsLoading, useSellerTypes, useTypedSelector } from 'src/hooks'
import {
  requestCreateAnalysis,
  requestUpdateAnalysis,
  ANALYSIS,
} from 'src/store/ducks/analysis'
import { COMPANY_TYPES } from 'src/store/ducks/company'

import {
  Container,
  Form,
  StyledInput,
  HideFormButton,
  SaveScreenButton,
  CompanyContent,
  CompanyList,
  CompanyLabel,
  StyledCheckBox,
  StyledSpinnerWithText,
} from './styles'

interface DashboardAnalysisScreenProps {
  handleHideScreenForm: () => void
  setEditingAnalysis: React.Dispatch<
    React.SetStateAction<UncDashboard.Analysis | null>
  >
  editingAnalysis: UncDashboard.Analysis | null
}

type SellerType = ReactSelectOption & {
  value: UncDashboard.UnionTypes.SellerType
}

type StringKeyBooleanValue = { [key: string]: boolean }

const AnalysisForm: React.FC<DashboardAnalysisScreenProps> = ({
  handleHideScreenForm,
  setEditingAnalysis,
  editingAnalysis,
}) => {
  const { t } = useTranslation(['CreateAnalysis', 'AnalysisType'])
  const { sellerTypesOptions } = useSellerTypes()
  const dispatch = useDispatch()

  const [analysisName, setAnalysisName] = useState('')

  const [
    selectedAnalysisType,
    setSelectedAnalysisType,
  ] = useState<ReactSelectOption | null>(null)

  const [
    selectedSellerType,
    setSelectedSellerType,
  ] = useState<SellerType | null>(null)

  const [selectedSalesType, setSelectedSalesType] = useState<
    ReactSelectOption[] | null
  >(null)

  const [selectedCompanies, setSelectedCompanies] = useState<boolean[]>([])

  const isSaving = useIsLoading(
    ANALYSIS.REQUEST_CREATE_ANALYSIS,
    ANALYSIS.REQUEST_UPDATE_ANALYSIS,
  )
  const isLoadingCompany = useIsLoading(COMPANY_TYPES.REQUEST_FETCH_COMPANIES)

  const isLoadingAnalysisTypes = useIsLoading(
    ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES,
  )

  const isEditing = useMemo(() => !!editingAnalysis, [editingAnalysis])

  const companyList = useTypedSelector(({ Company }) => {
    return Company.companyList
  })

  const salesTypeList = useTypedSelector(({ SalesType }) => {
    return SalesType.salesTypes
  })

  const analysisTypeList = useTypedSelector(({ AnalysisType }) => {
    return AnalysisType.analysisTypes
  })

  const salesTypeOptions: ReactSelectOption[] = useMemo(() => {
    return salesTypeList.map(
      ({ tipopedvenda_descricao, tipopedvenda_codigo }) => ({
        label: tipopedvenda_descricao,
        value: String(tipopedvenda_codigo),
      }),
    )
  }, [salesTypeList])

  const analysisTypeOptions: ReactSelectOption[] = useMemo(() => {
    return analysisTypeList.map(
      ({ tipoanalise_descricao, tipoanalise_codigo }) => ({
        label: tipoanalise_descricao,
        value: String(tipoanalise_codigo),
      }),
    )
  }, [analysisTypeList])

  const [errors, setErrors] = useState({
    isAnalysisTypeNotSelected: false,
    isAnalysisNameEmpty: false,
    isSellerTypeNotSelected: false,
  })

  const handleValidation = (): boolean => {
    const errorsObject: typeof errors = {
      isAnalysisTypeNotSelected: !selectedAnalysisType,
      isAnalysisNameEmpty: !analysisName.trim(),
      isSellerTypeNotSelected: !selectedSellerType,
    }

    setErrors(errorsObject)
    return Object.values(errorsObject).every((hasError) => !hasError)
  }

  const handleClearForm = () => {
    setSelectedAnalysisType(null)
    setAnalysisName('')
    setSelectedSellerType(null)
    setSelectedSalesType(null)
    setSelectedCompanies([])
  }
  const handleChangeDashboardCategory = (option: any) => {
    const dashboardCategoryOption = option as ReactSelectOption
    setSelectedAnalysisType(dashboardCategoryOption)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (handleValidation()) {
      const companyObject: StringKeyBooleanValue = {}
      const salesObject: StringKeyBooleanValue = {}
      const sellerType = selectedSellerType?.value
      const analysisType = Number(selectedAnalysisType?.value)
      if (!selectedSalesType) return

      selectedSalesType.forEach((salesTypeObject) => {
        const salesObjectClone = { ...salesTypeObject }
        const salesItem = salesObjectClone.value
        salesObject[salesItem] = true
      })

      const salesTypeListObject = JSON.stringify(salesObject)

      selectedCompanies.forEach((isChecked, index) => {
        const companyItem = companyList[index]
        const companyId = companyItem.empresa_codigo
        companyObject[companyId] = isChecked
      })

      const companyListObject = JSON.stringify(companyObject)
      if (!sellerType) return

      if (editingAnalysis) {
        dispatch(
          requestUpdateAnalysis({
            analise_codigo: editingAnalysis.analise_codigo,
            tipoanalise_codigo: analysisType,
            analise_descricao: analysisName,
            analise_tipovendedor: sellerType,
            analise_empresas: companyListObject,
            analise_tiposdevenda: salesTypeListObject,
            successCallback() {
              setEditingAnalysis(null)
            },
          }),
        )
      } else {
        dispatch(
          requestCreateAnalysis({
            tipoanalise_codigo: analysisType,
            analise_descricao: analysisName,
            analise_tipovendedor: sellerType,
            analise_empresas: companyListObject,
            analise_tiposdevenda: salesTypeListObject,
            successCallback: handleClearForm,
          }),
        )
      }
    }
  }

  const handleCheckCompany = (index: number, check: boolean) => {
    setSelectedCompanies((currentArray) => {
      const arrayClone = [...currentArray]
      arrayClone[index] = check
      return arrayClone
    })
  }

  useEffect(() => {
    if (editingAnalysis) {
      const sellerType = sellerTypesOptions.find((SellerType) => {
        return SellerType.value === editingAnalysis.analise_tipovendedor
      })
      if (!sellerType) return
      setSelectedSellerType(sellerType)
    }
  }, [editingAnalysis, sellerTypesOptions])

  useEffect(() => {
    if (editingAnalysis) {
      const name = editingAnalysis.analise_descricao || ''
      setAnalysisName(name)
    }
  }, [editingAnalysis])

  useEffect(() => {
    if (editingAnalysis) {
      const companySelected = editingAnalysis.analise_empresas
      if (!companySelected) return
      const companyObject: StringKeyBooleanValue = JSON.parse(companySelected)

      const selectedCompaniesToUpdate = companyList.map((company) => {
        return companyObject[company.empresa_codigo] || false
      })

      setSelectedCompanies(selectedCompaniesToUpdate)
    }
  }, [editingAnalysis, companyList])

  useEffect(() => {
    if (editingAnalysis) {
      const salesTypeSelected = editingAnalysis.analise_tiposdevenda

      if (!salesTypeSelected) return
      const salesTypeObject: StringKeyBooleanValue = JSON.parse(
        salesTypeSelected,
      )

      const selectedSalesTypeToUpdate = salesTypeOptions
        .map((salesTypeOption) => {
          const isSelectedSalesType = !!salesTypeObject[salesTypeOption.value]
          return isSelectedSalesType ? salesTypeOption : undefined
        })
        .filter((option) => !!option)

      setSelectedSalesType(selectedSalesTypeToUpdate as ReactSelectOption[])
    }
  }, [editingAnalysis, salesTypeOptions])

  useEffect(() => {
    if (editingAnalysis) {
      const selectedAnalysisFound = analysisTypeOptions.find((analysisType) => {
        return analysisType.value === String(editingAnalysis.tipoanalise_codigo)
      })

      if (!selectedAnalysisFound) return

      setSelectedAnalysisType(selectedAnalysisFound)
    }
  }, [editingAnalysis, analysisTypeOptions])

  useEffect(() => {
    dispatch(requestFetchAnalysisTypes())
    return () => {
      dispatch(setAnalysisTypesList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <HideFormButton
        onClick={() => {
          handleHideScreenForm()
          setEditingAnalysis(null)
        }}
      >
        <span>
          {t(
            isEditing
              ? 'cancelEditingAnalysisType'
              : 'cancelCreatingAnalysisType',
          )}
        </span>
        <FiTrash />
      </HideFormButton>

      <Form onSubmit={handleSubmit} noValidate>
        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="analysisName">
              <FiAlignCenter />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('AnalysisType:analysisNameLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              id="analysisName"
              type="text"
              value={analysisName}
              onChange={(e) => setAnalysisName(e.target.value)}
              autoCapitalize="off"
              placeholder={t('AnalysisType:analysisNamePh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isAnalysisNameEmpty}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="analysisType">
              <FiPieChart />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('AnalysisType:analysisTypeLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="analysisType"
              name="analysisType"
              value={selectedAnalysisType}
              options={analysisTypeOptions}
              isLoading={isLoadingAnalysisTypes}
              placeholder={t('AnalysisType:analysisTypePh')}
              noOptionsMessage={() => t('AnalysisType:noAnalysisTypesFound')}
              onChange={handleChangeDashboardCategory}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isAnalysisTypeNotSelected}
        />

        <CompanyContent>
          <CompanyLabel>
            <FiHome />
            <span>{t('AnalysisType:companyLabel')}</span>
          </CompanyLabel>

          <CompanyList>
            {isLoadingCompany ? (
              <StyledSpinnerWithText hasSpinnerOnTheLeft>
                <Spinner />
                <span>{t('Glossary:loading')}</span>
              </StyledSpinnerWithText>
            ) : (
              companyList.map((company, index) => {
                const isSelected = selectedCompanies[index] || false

                const handleChecked = () => {
                  handleCheckCompany(index, !isSelected)
                }

                return (
                  <StyledCheckBox
                    key={company.empresa_codigo}
                    checkMarkIcon={<FiCheck />}
                    checked={isSelected}
                    onChange={handleChecked}
                  >
                    <span className="label">{company.empresa_fantasia}</span>
                  </StyledCheckBox>
                )
              })
            )}
          </CompanyList>
        </CompanyContent>

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="sellerType">
              <FiUsers />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('AnalysisType:sellerTypeLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="sellerType"
              name="sellerType"
              value={selectedSellerType}
              options={sellerTypesOptions}
              placeholder={t('AnalysisType:sellerTypePh')}
              onChange={(option) => setSelectedSellerType(option as SellerType)}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isSellerTypeNotSelected}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="salesType">
              <FiShoppingCart />
              <span>{t('AnalysisType:salesTypeLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="salesType"
              name="salesType"
              options={salesTypeOptions}
              value={selectedSalesType}
              placeholder={t('AnalysisType:salesTypePh')}
              noOptionsMessage={() => t('CreateAnalysis:noSalesTypesFound')}
              onChange={(option) =>
                setSelectedSalesType(option as ReactSelectOption[])
              }
              isMulti
            />
          }
        />

        <SaveScreenButton type="submit" disabled={isSaving}>
          <span>{t(isEditing ? 'editAnalysisType' : 'saveAnalysisType')}</span>
          {isSaving ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveScreenButton>
      </Form>
    </Container>
  )
}

export default AnalysisForm
