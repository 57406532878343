import React from 'react'
import { FaCircle } from 'react-icons/fa'

import { Container } from './styles'

export interface ChartLegendProps {
  className?: string
  style?: React.CSSProperties
  label: string
  color: string
  percentage?: string
  value?: string
}

export const ChartLegend: React.FC<ChartLegendProps> = ({
  className,
  style,
  label,
  color,
  percentage,
  value,
  children,
}) => {
  return (
    <Container className={className} style={style}>
      <FaCircle color={color} />
      {!!percentage && <span>{percentage}</span>}
      {!!value && <span className="value">{value}</span>}
      <span>{label}</span>
      {children}
    </Container>
  )
}
