import React from 'react'

import {
  DashboardBarChart,
  DashboardDataCards,
  DashboardLineChart,
  DashboardPieChart,
  GoalEvolutionMonthByMonth,
  GoalEvolutionDayByDay,
  DashboardBarChartLegended,
} from 'src/components'
import { useTypedSelector } from 'src/hooks'
import { ANALYSIS_TYPES, SPECIFIC_CHARTS } from 'src/config'

import { Container, Charts } from './styles'

const DataVisualization: React.FC = () => {
  const cardList = useTypedSelector(({ AutoDashboard }) => {
    const screenList = AutoDashboard.screenList
    const currentScreen = screenList[AutoDashboard.currentScreenIndex]
    if (!currentScreen) return []

    return currentScreen.LISTA_ANALISES.filter(({ tipoanalise_tipo }) => {
      const isCard = tipoanalise_tipo === ANALYSIS_TYPES.CARD
      return isCard
    })
  })

  const chartList = useTypedSelector(({ AutoDashboard }) => {
    const screenList = AutoDashboard.screenList
    const currentScreen = screenList[AutoDashboard.currentScreenIndex]
    if (!currentScreen) return []

    return currentScreen.LISTA_ANALISES.filter(({ tipoanalise_tipo }) => {
      const isNotCard = tipoanalise_tipo !== ANALYSIS_TYPES.CARD
      return isNotCard
    })
  })

  const handleRenderSpecificCharts = (
    key: string,
    analysisCode: number,
    title: string,
    data: UncDashboard.ComposedTypes.DashboardDataValues[],
  ) => {
    switch (analysisCode) {
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_MONTH_BY_MONTH:
        return <GoalEvolutionMonthByMonth key={key} title={title} data={data} />
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_MONTH_BY_MONTH_INVOICE:
        return <GoalEvolutionMonthByMonth key={key} title={title} data={data} />
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_DAY_BY_DAY:
        return <GoalEvolutionDayByDay key={key} title={title} data={data} />
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_DAY_BY_DAY_INVOICE:
        return <GoalEvolutionDayByDay key={key} title={title} data={data} />
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_DAY_BY_DAY_BIWEEKLY_INVOICE:
        return <GoalEvolutionDayByDay key={key} title={title} data={data} />
      case SPECIFIC_CHARTS.GOAL_EVOLUTION_DAY_BY_DAY_WEEKLY_INVOICE:
        return <GoalEvolutionDayByDay key={key} title={title} data={data} />
      default:
        return null
    }
  }

  const handleRenderGenericCharts = (
    key: string,
    analysisType: UncDashboard.UnionTypes.AnalysisType,
    title: string,
    data: UncDashboard.ComposedTypes.DashboardDataValues[],
    analysisCode: number,
  ) => {
    switch (analysisType) {
      case ANALYSIS_TYPES.BAR_CHART:
        return <DashboardBarChart key={key} data={data} title={title} />

      case ANALYSIS_TYPES.PIE_CHART:
        return <DashboardPieChart key={key} data={data} title={title} />

      case ANALYSIS_TYPES.LINE_CHART:
        return <DashboardLineChart key={key} data={data} title={title} />

      case ANALYSIS_TYPES.BAR_CHART_LEG:
        return (
          <DashboardBarChartLegended
            key={key}
            data={data}
            title={title}
            analysisCode={analysisCode}
          />
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <DashboardDataCards cardList={cardList} />

      <Charts>
        {chartList.map(
          ({
            telaanalise_codigo,
            tipoanalise_codigo,
            tipoanalise_tipo,
            analise_descricao,
            DADOS,
          }) => {
            if (!DADOS) return null

            const specificChart = handleRenderSpecificCharts(
              `${telaanalise_codigo}_${Math.random()}`,
              tipoanalise_codigo,
              analise_descricao,
              DADOS,
            )

            if (specificChart) return specificChart

            return handleRenderGenericCharts(
              `${tipoanalise_codigo}_${Math.random()}`,
              tipoanalise_tipo,
              analise_descricao,
              DADOS,
              tipoanalise_codigo,
            )
          },
        )}
      </Charts>
    </Container>
  )
}

export default DataVisualization
