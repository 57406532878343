export enum AUTO_DASHBOARD_TYPES {
  REQUEST_FETCH_SUMMARY = '@AUTO_DASHBOARD/REQUEST_FETCH_SUMMARY',
  REQUEST_FETCH_INITIAL_DATA = '@AUTO_DASHBOARD/REQUEST_FETCH_INITIAL_DATA',

  SET_SUMMARY = '@AUTO_DASHBOARD/SET_SUMMARY',
  SET_SCREEN_LIST = '@AUTO_DASHBOARD/SET_SCREEN_LIST',
  SET_SCREEN_AT_INDEX = '@AUTO_DASHBOARD/SET_SCREEN_AT_INDEX',
  SET_CURRENT_SCREEN_INDEX = '@AUTO_DASHBOARD/SET_CURRENT_SCREEN_INDEX',

  GO_TO_NEXT_SCREEN = '@AUTO_DASHBOARD/GO_TO_NEXT_SCREEN',
  GO_TO_PREVIOUS_SCREEN = '@AUTO_DASHBOARD/GO_TO_PREVIOUS_SCREEN',
}

export interface AutoDashboardState {
  currentScreenIndex: number
  summary?: UncDashboard.ComposedTypes.DashboardData
  screenList: UncDashboard.ComposedTypes.DashboardDataScreen[]
}

export interface RequestFetchSummaryAction {
  type: AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY
  payload: {
    dashboardId: number | {}
  }
}

export interface RequestFetchAutoDashboardInitialDataAction {
  type: AUTO_DASHBOARD_TYPES.REQUEST_FETCH_INITIAL_DATA
}

export interface SetSummaryAction {
  type: AUTO_DASHBOARD_TYPES.SET_SUMMARY
  payload: {
    summary?: UncDashboard.ComposedTypes.DashboardData
  }
}

export interface SetScreenListAction {
  type: AUTO_DASHBOARD_TYPES.SET_SCREEN_LIST
  payload: {
    screenList: UncDashboard.ComposedTypes.DashboardDataScreen[]
  }
}

export interface SetCurrentScreenIndexAction {
  type: AUTO_DASHBOARD_TYPES.SET_CURRENT_SCREEN_INDEX
  payload: {
    currentScreenIndex: number
  }
}

export interface SetScreenAtIndexAction {
  type: AUTO_DASHBOARD_TYPES.SET_SCREEN_AT_INDEX
  payload: {
    index: number
    screen: UncDashboard.ComposedTypes.DashboardDataScreen
  }
}

export interface GoToNextScreenAction {
  type: AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN
}

export interface GoToPreviousScreenAction {
  type: AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN
}
