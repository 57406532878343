import styled, { css } from 'styled-components'

const TextWithIcons = css`
  display: flex;
  align-items: center;

  span {
    margin-left: 1.6rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem;

  background-color: ${(props) => props.theme.background};

  border: none;

  cursor: pointer;
`

export const Title = styled.div`
  ${TextWithIcons};

  font-size: 1.8rem;
  font-weight: 900;
`

export const Text = styled.div`
  ${TextWithIcons};

  color: ${(props) => props.theme.secondaryText};
`
