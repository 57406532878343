import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'
import { LOCAL_STORAGE_KEYS } from 'src/config'

import { DASHBOARD_SCREEN_TYPES, SelectedDashboardScreen } from './types'
import { setDashboardScreenList, setSelectedDashboardScreen } from './actions'

export function* requestFetchDashboardScreensWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_FETCH,
    handleFetchDashboardScreens,
  )
}

export function* handleSelectDashboardScreen(
  firstScreenFromList: UncDashboard.DashboardScreen | null | undefined,
) {
  const savedDashboardScreenJson: string | null = yield call(
    [localStorage, localStorage.getItem],
    LOCAL_STORAGE_KEYS.SELECTED_DASHBOARD_SCREEN,
  )

  const dashboardScreen: SelectedDashboardScreen | null =
    savedDashboardScreenJson ? JSON.parse(savedDashboardScreenJson) : null

  if (dashboardScreen) {
    yield put(setSelectedDashboardScreen(dashboardScreen))
  } else if (firstScreenFromList) {
    yield put(
      setSelectedDashboardScreen({
        label: firstScreenFromList.teladash_descricao,
        value: String(firstScreenFromList.teladash_codigo),
      }),
    )
  }
}

export function* handleFetchDashboardScreens() {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH))

    const { data }: AxiosServerResponse<UncDashboard.DashboardScreen> =
      yield call(Server.put, DASHBOARD_SCREEN_ROUTES.GET_POR_CAMPOS, {})

    if (data.isSuccessful) {
      yield put(setDashboardScreenList(data.data))
      const firstScreenFromList = data.getFirstData()
      yield call(handleSelectDashboardScreen, firstScreenFromList)
    } else {
      yield put(setDashboardScreenList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_FETCH))
  }
}
