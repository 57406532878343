import {
  COMPANY_TYPES,
  RequestFetchCompaniesAction,
  SetCompanyListAction,
} from './types'

export const requestFetchCompanies = (): RequestFetchCompaniesAction => ({
  type: COMPANY_TYPES.REQUEST_FETCH_COMPANIES,
})

export const setCompanyList = (
  companyList: UncDashboard.Company[],
): SetCompanyListAction => ({
  type: COMPANY_TYPES.SET_COMPANY_LIST,
  payload: {
    companyList,
  },
})
