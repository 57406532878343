import React, { useMemo } from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts'

import { formatNumber } from 'src/utils'

import { FullWidthChart } from '../styles'

import { PieLegend } from './styles'
import { ValueType } from 'recharts/types/component/DefaultTooltipContent'

export interface DashboardPieChartProps {
  data: UncDashboard.ComposedTypes.DashboardDataValues[]
  title: string
}

export const DashboardPieChart: React.FC<DashboardPieChartProps> = ({
  data,
  title,
}) => {
  function handleFormatValues2<TValue extends ValueType>(value: TValue) {
    return `R$ ${formatNumber(Number(value), { defaultValue: String(value) })}`
  }

  const handleFormatValues = (value: number) => {
    return `R$ ${formatNumber(value, { defaultValue: String(value) })}`
  }

  const colorArray: string[] = useMemo(() => {
    return data.map((_, index) => {
      return `hsl(${360 - index * 25}, 90%, 40%)`
    })
  }, [data])

  return (
    <FullWidthChart title={title}>
      <ResponsiveContainer minHeight="30rem">
        <PieChart margin={{ bottom: 16, left: 0, right: 0, top: 0 }}>
          <Tooltip formatter={handleFormatValues2} />

          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius="90%"
            dataKey="serie_valor"
            nameKey="serie_nome"
            label={(entry: UncDashboard.ComposedTypes.DashboardDataValues) => {
              return `${entry.serie_percentual}%`
            }}
          >
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colorArray[index % colorArray.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {data.map((entry, index) => {
        return (
          <PieLegend
            key={`pie-legend-${index}`}
            label={entry.serie_nome || ''}
            percentage={`${entry.serie_percentual}%`}
            color={colorArray[index % colorArray.length]}
            value={handleFormatValues(entry.serie_valor || 0)}
          />
        )
      })}
    </FullWidthChart>
  )
}
