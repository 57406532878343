import styled from 'styled-components'

import { AnalysisItem, SpinnerWithText } from 'src/components'

export const StyledAnalysisItem = styled(AnalysisItem)`
  margin: 0.8rem auto;
`
export const List = styled.div`
  margin-top: 2.4rem;
`

export const Actions = styled.div`
  display: flex;

  > :first-child {
    margin: 0 0.8rem !important;
  }
`

export const StyledAnalysisModal = styled(AnalysisItem)`
  margin: 0.8rem auto;
  border: none;
`

export const StyledSpinnerWithText = styled(SpinnerWithText)`
  margin: 2.4rem 0;
`
