import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'
import { showSuccessToast } from 'src/store/ducks/success'

import { DASHBOARD_TYPE_TYPES, RequestRemoveDashboardTypeAction } from './types'
import { getDashboardTypeList } from './selects'
import { setDashboardTypesList } from './actions'

export function* removeDashboardTypeWatcher() {
  yield takeLatest(
    DASHBOARD_TYPE_TYPES.REQUEST_REMOVE_DASHBOARD_TYPE,
    handleRemoveDashboardType,
  )
}

export function* handleRemoveDashboardType(
  action: RequestRemoveDashboardTypeAction,
) {
  try {
    yield put(addLoading(DASHBOARD_TYPE_TYPES.REQUEST_REMOVE_DASHBOARD_TYPE))

    const { payload } = action

    const routesArray = [DASHBOARD_ROUTES.DASHBOARD, payload.id]
    const { data }: AxiosServerResponse<UncDashboard.Dashboard> = yield call(
      Server.delete,
      routesArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'removeDashboardType' }))

      const dashboardTypes: UncDashboard.Dashboard[] = yield select(
        getDashboardTypeList,
      )
      const updatedDashboardTypeList = dashboardTypes.filter(
        (dashboardType) => dashboardType.dash_codigo !== payload.id,
      )

      yield put(setDashboardTypesList(updatedDashboardTypeList))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_TYPE_TYPES.REQUEST_REMOVE_DASHBOARD_TYPE))
  }
}
