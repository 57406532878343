import Common from './Common.json'
import Error from './Error.json'
import Glossary from './Glossary.json'
import Success from './Success.json'
import PageTitles from './PageTitles.json'
import PageNotFound from './PageNotFound.json'
import ForgotPassword from './ForgotPassword.json'
import Login from './Login.json'
import Main from './Main.json'
import ResetPassword from './ResetPassword.json'
import Authorization from './Authorization.json'
import Dashboard from './Dashboard.json'
import DashboardScreen from './DashboardScreen.json'
import AutomaticDashboard from './AutomaticDashboard.json'
import DashboardScreenForm from './DashboardScreenForm.json'
import SellerTypes from './SellerTypes.json'
import Analysis from './Analysis.json'
import PageInDevelopment from './PageInDevelopment.json'
import GoalEvolutionMonthByMonth from './GoalEvolutionMonthByMonth.json'
import GoalEvolutionDayByDay from './GoalEvolutionDayByDay.json'
import CreateDashboardType from './CreateDashboardType.json'
import DashboardCategories from './DashboardCategories.json'
import AnalysisType from './AnalysisType.json'
import DateTime from './DateTime.json'
import DashboardAnalysis from './DashboardAnalysis.json'
import CreateAnalysis from './CreateAnalysis.json'
import CreateDashboardScreen from './CreateDashboardScreen.json'

export default {
  Common,
  Error,
  Glossary,
  Success,
  PageTitles,
  PageNotFound,
  ForgotPassword,
  Login,
  Main,
  ResetPassword,
  Authorization,
  Dashboard,
  DashboardScreen,
  AutomaticDashboard,
  DashboardScreenForm,
  SellerTypes,
  Analysis,
  PageInDevelopment,
  GoalEvolutionMonthByMonth,
  GoalEvolutionDayByDay,
  CreateDashboardType,
  DashboardCategories,
  DateTime,
  AnalysisType,
  DashboardAnalysis,
  CreateAnalysis,
  CreateDashboardScreen,
}
