import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useTypedSelector } from 'src/hooks'
import { requestGetDashboardData } from 'src/store/ducks/dashboard'

export default () => {
  const dispatch = useDispatch()

  const selectedScreen = useTypedSelector(
    ({ DashboardScreen }) => DashboardScreen.selectedScreen,
  )

  useEffect(() => {
    if (selectedScreen) {
      const dashboardScreenId = Number(selectedScreen.value)

      dispatch(requestGetDashboardData(dashboardScreenId))
    }
  }, [dispatch, selectedScreen])
}
