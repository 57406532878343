export interface FormatNumberOptions {
  decimalLength?: number
  decimalDelimiter?: string
  sectionsLength?: number
  sectionsDelimiter?: string
  defaultValue?: string
}

export const formatNumber = (
  number: number,
  {
    decimalLength = 2,
    decimalDelimiter = ',',
    sectionsLength = 3,
    sectionsDelimiter = '.',
    defaultValue = '',
  }: FormatNumberOptions = {},
): string => {
  try {
    const regex = `\\d(?=(\\d{${sectionsLength}})+${
      decimalLength > 0 ? '\\D' : '$'
    })`

    const regexInstance = new RegExp(regex, 'g')
    const numberString = Number(number).toFixed(Math.max(0, ~~decimalLength))

    const numberStringWithDecimalDelimiter = decimalDelimiter
      ? numberString.replace('.', decimalDelimiter)
      : numberString

    return numberStringWithDecimalDelimiter.replace(
      regexInstance,
      `$&${sectionsDelimiter}`,
    )
  } catch (e) {
    return defaultValue
  }
}
