import { Reducer } from 'redux'

import { AUTO_DASHBOARD_TYPES, AutoDashboardState } from './types'

export const initialState: AutoDashboardState = {
  currentScreenIndex: 0,
  screenList: [],
}

export const AutoDashboardReducer: Reducer<AutoDashboardState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case AUTO_DASHBOARD_TYPES.SET_SUMMARY: {
      return {
        ...state,
        summary: payload.summary,
      }
    }

    case AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN: {
      const summaryScreens = state.summary?.LISTA_TELAS
      if (!summaryScreens) return state

      const possibleNextIndex = state.currentScreenIndex + 1
      const isIndexOutOfBounds = possibleNextIndex === summaryScreens.length
      const nextScreenIndex = isIndexOutOfBounds ? 0 : possibleNextIndex

      return {
        ...state,
        currentScreenIndex: nextScreenIndex,
      }
    }

    case AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN: {
      const summaryScreens = state.summary?.LISTA_TELAS
      if (!summaryScreens) return state

      const possiblePrevIndex = state.currentScreenIndex - 1
      const isIndexSmallerThanZero = possiblePrevIndex < 0
      const prevScreenIndex = isIndexSmallerThanZero
        ? summaryScreens.length - 1
        : possiblePrevIndex

      return {
        ...state,
        currentScreenIndex: prevScreenIndex,
      }
    }

    case AUTO_DASHBOARD_TYPES.SET_CURRENT_SCREEN_INDEX: {
      return {
        ...state,
        currentScreenIndex: payload.currentScreenIndex,
      }
    }

    case AUTO_DASHBOARD_TYPES.SET_SCREEN_LIST: {
      return {
        ...state,
        screenList: payload.screenList,
      }
    }

    case AUTO_DASHBOARD_TYPES.SET_SCREEN_AT_INDEX: {
      const screenListClone = [...state.screenList]
      screenListClone[payload.index] = payload.screen

      return {
        ...state,
        screenList: screenListClone,
      }
    }

    default:
      return state
  }
}
