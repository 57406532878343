import React from 'react'

import { formatNumber } from 'src/utils'

import { FullWidthChart } from '../styles'

import { Table, Content } from './styles'

export interface GoalEvolutionDayByDayProps {
  title: string
  data: UncDashboard.ComposedTypes.DashboardDataValues[]
}

export const GoalEvolutionDayByDay: React.FC<GoalEvolutionDayByDayProps> = ({
  title,
  data,
}) => {
  const handleFormatValues = (value: number) => {
    return formatNumber(value, { defaultValue: String(value) })
  }

  const d = new Date()

  return (
    <FullWidthChart title={title}>
      <Content>
        <Table>
          <tbody>
            <tr>
              <th></th>
              {data.map((entry, index) => (
                <th key={`header-${index}`}>{entry.serie_titulo}</th>
              ))}
            </tr>

            <tr>
              <th>Meta Comercial</th>
              {data.map((entry, index) => (
                <td
                  key={`goal-${index}`}
                  className={
                    entry.dia === d.getDate()
                      ? 'current_day'
                      : entry.diautil === 'FDS'
                      ? 'weekend'
                      : entry.diautil === 'F'
                      ? 'holiday'
                      : ''
                  }
                >
                  {handleFormatValues(entry.serie_metacomercial || 0)}
                </td>
              ))}
            </tr>

            <tr>
              <th>Meta Re-calculada</th>
              {data.map((entry, index) => (
                <td
                  key={`goal-${index}`}
                  className={
                    entry.dia === d.getDate()
                      ? 'current_day'
                      : entry.diautil === 'FDS'
                      ? 'weekend'
                      : entry.diautil === 'F'
                      ? 'holiday'
                      : ''
                  }
                >
                  {handleFormatValues(entry.serie_metarecalculada || 0)}
                </td>
              ))}
            </tr>

            <tr>
              <th>Vendas Efetivadas</th>
              {data.map((entry, index) => (
                <td
                  key={`sales-${index}`}
                  className={
                    entry.dia === d.getDate()
                      ? 'current_day'
                      : entry.diautil === 'FDS'
                      ? 'weekend'
                      : entry.diautil === 'F'
                      ? 'holiday'
                      : ''
                  }
                >
                  {handleFormatValues(entry.serie_vendasefetivadas || 0)}
                </td>
              ))}
            </tr>

            <tr>
              <th>% da Meta Atingida</th>
              {data.map((entry, index) => {
                const percentage = `${
                  entry.serie_percentualatingido || ''
                }`.replace('.', ',')

                return (
                  <td
                    key={`percent-${index}`}
                    className={
                      entry.dia === d.getDate()
                        ? 'current_day'
                        : entry.diautil === 'FDS'
                        ? 'weekend'
                        : entry.diautil === 'F'
                        ? 'holiday'
                        : ''
                    }
                  >
                    {percentage ? `${percentage}%` : ''}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </Table>
      </Content>
    </FullWidthChart>
  )
}
