import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  serverStructureFactory,
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'

import { showSuccessToast } from '../success'

import {
  DASHBOARD_SCREEN_TYPES,
  RequestCreateDashboardScreenAction,
} from './types'
// import { getDashboardScreenList } from './selects'
import { requestFetchFullDashboardScreen } from './actions'

type RequestData = Pick<
  UncDashboard.ComposedTypes.FullDashboardScreen,
  'dash_codigo' | 'dashtelas_seq' | 'teladash_descricao' | 'TELADASH_ANALISE'
>

export function* requestCreateDashboardScreenWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_CREATE,
    handleCreateDashboardScreen,
  )
}

export function* handleCreateDashboardScreen(
  action: RequestCreateDashboardScreenAction,
) {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_CREATE))

    const { payload } = action

    const requestData: RequestData = {
      TELADASH_ANALISE: payload.teladash_analise,
      dashtelas_seq: payload.dashtelas_seq,
      dash_codigo: payload.dash_codigo,
      teladash_descricao: payload.teladash_descricao,
    }

    const {
      data,
    }: AxiosServerResponse<
      UncDashboard.ComposedTypes.FullDashboardScreen,
      RequestData
    > = yield call(
      Server.put,
      DASHBOARD_SCREEN_ROUTES.DASHBOARD_TELAS,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'createDashboardScreen' }))
      const createdDashboardScreen = data.getFirstData()
      if (!createdDashboardScreen) return

      // const dashboardScreens: UncDashboard.ComposedTypes.GetDashboardScreen[] = yield select(
      //   getDashboardScreenList,
      // )

      // const dashboardList = dashboardScreens.map((Dashboard) => {
      //   Dashboard.LISTA_TELAS.map((ScreenList) => {
      //     return [ScreenList, createdDashboardScreen.LISTA_TELAS]
      //   })

      //   return Dashboard
      // })

      yield put(requestFetchFullDashboardScreen())
      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_CREATE))
  }
}
