import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FiCheckCircle,
  FiChevronLeft,
  FiMail,
  FiSend,
  FiHelpCircle,
} from 'react-icons/fi'

import { hideSuccess } from 'src/store/ducks/success'
import { DefaultLabel, SnowInput, WhiteSpinner } from 'src/components'
import { PASSWORD_TYPES, requestForgotPassword } from 'src/store/ducks/password'
import {
  useIsLoading,
  useSetBrowserTabTitle,
  useIsShowingSuccess,
} from 'src/hooks'

import {
  Container,
  Form,
  StyledPageTitle,
  EmailInput,
  SendButton,
  SuccessContainer,
  SuccessTitle,
  SuccessMessage,
  BackButton,
} from './styles'

const ForgotPassword: React.FC = () => {
  useSetBrowserTabTitle('forgotPassword')

  const { t } = useTranslation(['ForgotPassword', 'Glossary'])
  const dispatch = useDispatch()
  const history = useHistory()

  const emailRef = useRef<HTMLInputElement | null>(null)

  const isLoading = useIsLoading(PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD)

  const isShowingSuccess = useIsShowingSuccess(
    PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD,
  )

  const handleValidation = (email: string): boolean => {
    if (email.trim()) return true
    emailRef.current?.focus()
    return false
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const email = emailRef.current?.value || ''
    if (handleValidation(email)) {
      dispatch(requestForgotPassword(email))
    }
  }

  const handleBackToLogin = () => {
    history.goBack()
  }

  useEffect(() => {
    return () => {
      dispatch(hideSuccess(PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD))
    }
  }, [dispatch])

  return (
    <Container>
      {isShowingSuccess ? (
        <SuccessContainer>
          <SuccessTitle>
            <FiCheckCircle />
            <span>{t('successTitle')}</span>
          </SuccessTitle>

          <SuccessMessage>{t('successMessage')}</SuccessMessage>

          <BackButton onClick={handleBackToLogin}>
            <FiChevronLeft />
            <span>{t('backButton')}</span>
          </BackButton>
        </SuccessContainer>
      ) : (
        <Form onSubmit={handleSubmit} noValidate>
          <StyledPageTitle
            title={t('title')}
            subtitle={t('subtitle')}
            iconComponent={<FiHelpCircle />}
          />

          <EmailInput
            labelComponent={
              <DefaultLabel htmlFor="email">
                <FiMail />
                <span className="required">{t('Glossary:required')}</span>
                <span>{t('emailLabel')}</span>
              </DefaultLabel>
            }
            inputComponent={
              <SnowInput
                id="email"
                ref={emailRef}
                type="email"
                disabled={isLoading}
                autoCapitalize="off"
                placeholder={t('emailPh')}
              />
            }
          />

          <SendButton type="submit" disabled={isLoading}>
            <span>{t('sendButton')}</span>
            {isLoading ? <WhiteSpinner /> : <FiSend />}
          </SendButton>
        </Form>
      )}
    </Container>
  )
}

export default ForgotPassword
