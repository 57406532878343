import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  serverStructureFactory,
  AxiosServerResponse,
  DASHBOARD_ROUTES,
  Server,
} from 'src/services'

import { showSuccessToast } from '../success'

import { DASHBOARD_TYPE_TYPES, RequestCreateDashboardTypeAction } from './types'
import { getDashboardTypeList } from './selects'
import { setDashboardTypesList } from './actions'

type RequestData = Omit<UncDashboard.Dashboard, 'dash_codigo'>

export function* requestCreateDashboardTypeWatcher() {
  yield takeLatest(
    DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE,
    handleCreateDashboardType,
  )
}

export function* handleCreateDashboardType(
  action: RequestCreateDashboardTypeAction,
) {
  try {
    yield put(addLoading(DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE))

    const { payload } = action

    const requestData: RequestData = {
      dash_descricao: payload.description,
      dash_tipo: payload.dashboardCategory,
      dash_automatico: payload.isAutomatic ? 'S' : 'N',
      dash_tempotelas: Number(payload.duration),
      dash_situacao: 'A',
    }

    const { data }: AxiosServerResponse<UncDashboard.Dashboard> = yield call(
      Server.put,
      DASHBOARD_ROUTES.DASHBOARD,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'createDashboardType' }))
      const createdDashboardType = data.getFirstData()
      if (!createdDashboardType) return

      const dashboardTypes: UncDashboard.Dashboard[] = yield select(
        getDashboardTypeList,
      )

      yield put(
        setDashboardTypesList([...dashboardTypes, createdDashboardType]),
      )

      if (payload.successCallback) payload.successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE))
  }
}
