import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1.6rem;
  }

  span {
    font-size: 1.4rem;
  }

  & > *:not(:first-child) {
    margin-left: 0.8rem;
  }

  .value {
    font-weight: bold;
  }
`
