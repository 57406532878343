import styled from 'styled-components'

import { RoundIconButton } from 'src/components'
import { NoWrapEllipsis, SIZES } from 'src/styles'

export const Navbar = styled.div`
  height: ${SIZES.NAVBAR_HEIGHT};
  width: 100%;

  padding: 0 1.6rem;

  display: flex;
  align-items: center;
`

export const DrawerToggler = styled(RoundIconButton)``

export const Logo = styled.img`
  height: 3rem;
  width: 3rem;

  margin-left: 1.6rem;
`

export const NavbarTitle = styled.div`
  ${NoWrapEllipsis};
  font-size: 1.8rem;

  margin-left: 0.8rem;
`
