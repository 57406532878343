import React from 'react'

import { Container, Title, Content } from './styles'

export interface ChartContainerProps {
  style?: React.CSSProperties
  className?: string
  title: string
}

export const ChartContainer: React.FC<ChartContainerProps> = (props) => {
  const { className, style, title, children } = props

  return (
    <Container className={className} style={style}>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}
