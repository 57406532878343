import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showSuccessToast } from 'src/store/ducks/success'
import { AxiosServerResponse, ANALYSIS_ROUTES, Server } from 'src/services'

import { ANALYSIS, RequestRemoveAnalysisAction } from './types'
// import { getAnalysisList } from './selects'
import { requestFetchAnalysis } from './actions'

export function* requestRemoveAnalysisWatcher() {
  yield takeLatest(ANALYSIS.REQUEST_REMOVE_ANALYSIS, handleRemoveAnalysis)
}

export function* handleRemoveAnalysis(action: RequestRemoveAnalysisAction) {
  try {
    yield put(addLoading(ANALYSIS.REQUEST_REMOVE_ANALYSIS))

    const { id } = action.payload

    const routesArray = [ANALYSIS_ROUTES.ANALISE, id]
    const { data }: AxiosServerResponse<UncDashboard.Analysis> = yield call(
      Server.delete,
      routesArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'removeAnalysis' }))

      // const analysisTypes: UncDashboard.Analysis[] = yield select(
      //   getAnalysisList,
      // )

      // const updatedAnalysisTypeList = analysisTypes.filter(
      //   (dashboardType) => dashboardType.tipoanalise_codigo !== id,
      // )

      // yield put(setAnalysisList(updatedAnalysisTypeList))
      yield put(requestFetchAnalysis())
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(ANALYSIS.REQUEST_REMOVE_ANALYSIS))
  }
}
