import { RootState } from 'src/store/rootReducer'

export const getCurrentScreenIndex = ({ AutoDashboard }: RootState): number => {
  return AutoDashboard.currentScreenIndex
}

export const getNextScreenIndex = ({ AutoDashboard }: RootState): number => {
  const currentScreenIndex = AutoDashboard.currentScreenIndex
  const screenList = AutoDashboard.summary?.LISTA_TELAS
  if (!screenList) return 0

  const nextScreenIndex = currentScreenIndex + 1
  if (nextScreenIndex === screenList.length) return 0
  return nextScreenIndex
}

export const getPreviousScreenIndex = ({
  AutoDashboard,
}: RootState): number => {
  const currentScreenIndex = AutoDashboard.currentScreenIndex
  const screenList = AutoDashboard.summary?.LISTA_TELAS
  if (!screenList) return 0

  const previousScreenIndex = currentScreenIndex - 1
  if (previousScreenIndex < 0) return screenList.length - 1
  return previousScreenIndex
}

export const getScreenIdCurried = (index: number) => ({
  AutoDashboard,
}: RootState): number => {
  const screenList = AutoDashboard.summary?.LISTA_TELAS
  if (!screenList) return 0

  const screen = screenList[index]
  if (!screen) return 0

  return screen.teladash_codigo
}

export const getSummaryScreenList = ({
  AutoDashboard,
}: RootState): UncDashboard.ComposedTypes.DashboardDataScreen[] => {
  return AutoDashboard.summary?.LISTA_TELAS || []
}
