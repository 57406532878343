import styled from 'styled-components'

import { Input, PageTitle, AccentButton } from 'src/components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100%;

  padding: 2.4rem;
`

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const Form = styled.form`
  width: 100%;
  max-width: 60rem;
`

export const PasswordInput = styled(Input)`
  margin-bottom: 2.4rem;
`

export const SaveButton = styled(AccentButton)`
  margin-bottom: 2.4rem;
`
