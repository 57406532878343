import React from 'react'
import {
  FiArrowDown,
  FiCheck,
  FiClock,
  FiFileText,
  FiPause,
  FiPlay,
  FiTag,
  FiX,
} from 'react-icons/fi'

import {
  Container,
  Content,
  Actions,
  Title,
  Text,
  SituationText,
  MoreDetails,
} from './styles'

export interface DashboardTypeItemProps {
  className?: string
  style?: React.CSSProperties
  description?: string
  duration?: string
  situationText?: string
  isActive?: boolean
  category?: string
  isAutomatic?: string
  onClick?: (e: React.MouseEvent) => void
  isSelected?: boolean
  rightSideComponent?: React.ReactNode
  moreDetails?: React.ReactNode
}

export const DashboardTypeItem: React.FC<DashboardTypeItemProps> = (props) => {
  const {
    style,
    onClick,
    duration,
    category,
    className,
    isAutomatic,
    moreDetails,
    description,
    situationText,
    isActive = false,
    isSelected = false,
    rightSideComponent,
  } = props

  return (
    <Container
      onClick={onClick}
      className={className}
      style={style}
      isSelected={isSelected}
    >
      <Content>
        <Title>
          <FiFileText />
          <span>{description}</span>
        </Title>

        <Text>
          <FiTag />
          <span>{category}</span>
        </Text>

        <Text>
          <FiClock />
          <span>{duration}</span>
        </Text>

        {isAutomatic ? (
          <Text>
            {isAutomatic ? <FiPlay /> : <FiPause />}
            <span>{isAutomatic}</span>
          </Text>
        ) : null}

        <SituationText isActive={isActive}>
          {isActive ? <FiCheck /> : <FiX />}
          <span>{situationText}</span>
        </SituationText>

        {moreDetails ? (
          <MoreDetails>
            <FiArrowDown />
            <span>{moreDetails}</span>
          </MoreDetails>
        ) : null}
      </Content>

      <Actions>{rightSideComponent}</Actions>
    </Container>
  )
}
