import { Reducer } from 'redux'

import { ANALYSIS, AnalysisState } from './types'

export const initialState: AnalysisState = {
  analysis: [],
}

export const AnalysisReducer: Reducer<AnalysisState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case ANALYSIS.SET_ANALYSIS_LIST: {
      return {
        ...state,
        analysis: payload.analysis,
      }
    }

    default:
      return state
  }
}
