export enum ANALYSIS {
  REQUEST_FETCH_ANALYSIS = '@ANALYSIS/REQUEST_FETCH_ANALYSIS',
  SET_ANALYSIS_LIST = '@ANALYSIS/SET_ANALYSIS_LIST',
  REQUEST_CREATE_ANALYSIS = '@ANALYSIS/REQUEST_CREATE_ANALYSIS',
  REQUEST_UPDATE_ANALYSIS = '@ANALYSIS/REQUEST_UPDATE_ANALYSIS',
  REQUEST_REMOVE_ANALYSIS = '@ANALYSIS/REQUEST_REMOVE_ANALYSIS',
}

export interface AnalysisState {
  analysis: UncDashboard.Analysis[]
}

export interface RequestFetchAnalysisAction {
  type: ANALYSIS.REQUEST_FETCH_ANALYSIS
}

export interface SetAnalysisListAction {
  type: ANALYSIS.SET_ANALYSIS_LIST
  payload: {
    analysis: UncDashboard.Analysis[]
  }
}

export interface RequestCreateAnalysisAction {
  type: ANALYSIS.REQUEST_CREATE_ANALYSIS
  payload: {
    tipoanalise_codigo: number
    analise_descricao: string
    analise_tipovendedor: UncDashboard.UnionTypes.SellerType
    analise_tiposdevenda?: string
    analise_empresas?: string
    successCallback?: () => void
  }
}

export interface RequestUpdateAnalysisAction {
  type: ANALYSIS.REQUEST_UPDATE_ANALYSIS
  payload: {
    analise_codigo: number
    analise_descricao: string
    tipoanalise_codigo: number
    analise_tipovendedor: UncDashboard.UnionTypes.SellerType
    analise_tiposdevenda?: string
    analise_empresas?: string
    successCallback?: () => void
  }
}

export interface RequestRemoveAnalysisAction {
  type: ANALYSIS.REQUEST_REMOVE_ANALYSIS
  payload: {
    id: number
  }
}
