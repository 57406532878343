import React from 'react'
import {
  FiAlignCenter,
  FiHome,
  FiPieChart,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi'

import { Container, Content, Actions, Text, Title } from './styles'

export interface AnalysisItemProps {
  className?: string
  style?: React.CSSProperties
  isSelected?: boolean
  onClick?: (e: React.MouseEvent) => void
  name: string
  analysisType: string
  companies?: string
  sellerType: string
  salesTypes?: string
  rightSideComponent?: React.ReactNode
  showMoreIcon?: React.ReactNode
}

export const AnalysisItem: React.FC<AnalysisItemProps> = (props) => {
  const {
    className,
    style,
    isSelected = false,
    onClick,
    name,
    analysisType,
    companies,
    sellerType,
    salesTypes,
    rightSideComponent,
    showMoreIcon,
  } = props

  return (
    <Container
      onClick={onClick}
      isSelected={isSelected}
      className={className}
      style={style}
    >
      <Content>
        <Title>
          <FiAlignCenter />
          <span>{name}</span>
        </Title>

        <Text>
          <FiPieChart />
          <span>{analysisType}</span>
        </Text>

        {sellerType ? (
          <Text>
            <FiUser />
            <span>{sellerType}</span>
          </Text>
        ) : null}

        {!!salesTypes && (
          <Text>
            <FiShoppingCart />
            <span>{salesTypes}</span>
          </Text>
        )}

        {!!companies && (
          <Text>
            <FiHome />
            <span>{companies}</span>
          </Text>
        )}

        {showMoreIcon && <Actions>{showMoreIcon}</Actions>}
      </Content>

      {rightSideComponent && <Actions>{rightSideComponent}</Actions>}
    </Container>
  )
}
