import React from 'react'
import { FiAlignCenter, FiPieChart } from 'react-icons/fi'

import { Container, Text, Title } from './styles'

export interface AnalysisScreenProps {
  name: string
  className?: string
  analysisType: string
  style?: React.CSSProperties
}

export const AnalysisScreen: React.FC<AnalysisScreenProps> = (props) => {
  const { className, style, name, analysisType } = props

  return (
    <Container className={className} style={style}>
      <Title>
        <FiAlignCenter />
        <span>{name}</span>
      </Title>

      <Text>
        <FiPieChart />
        <span>{analysisType}</span>
      </Text>
    </Container>
  )
}
