import React from 'react'
import { useTheme } from 'styled-components'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'

import { Chart } from '../styles'

export interface DashboardLineChartProps {
  data: UncDashboard.ComposedTypes.DashboardDataValues[]
  title: string
}

export const DashboardLineChart: React.FC<DashboardLineChartProps> = ({
  data,
  title,
}) => {
  const theme = useTheme()

  return (
    <Chart title={title}>
      <ResponsiveContainer minHeight="30rem">
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="serie_nome" />

          <YAxis />
          <Tooltip />
          <Legend />

          <Line
            type="monotone"
            dataKey="serie_valor"
            stroke={theme.accent}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </Chart>
  )
}
