export enum DASHBOARD_SCREEN_TYPES {
  REQUEST_FETCH = '@DASHBOARD_SCREEN/REQUEST_FETCH',
  REQUEST_CREATE = '@DASHBOARD_SCREEN/REQUEST_CREATE',
  REQUEST_UPDATE = '@DASHBOARD_SCREEN/REQUEST_UPDATE',
  REQUEST_REMOVE = '@DASHBOARD_SCREEN/REQUEST_REMOVE',
  REQUEST_FETCH_FULL = '@DASHBOARD_SCREEN/REQUEST_FETCH_FULL',
  REQUEST_GET_NEXT_SEQUENCE = '@DASHBOARD_SCREEN/REQUEST_GET_NEXT_SEQUENCE',

  SET_SELECTED_SCREEN = '@DASHBOARD_SCREEN/SET_SELECTED_SCREEN',
  SET_SELECTED_DASHBOARD = '@DASHBOARD_SCREEN/SET_SELECTED_DASHBOARD',
  SET_LIST = '@DASHBOARD_SCREEN/SET_LIST',
  SET_FULL_LIST = '@DASHBOARD_SCREEN/SET_FULL_LIST',
  SET_NEXT_SEQUENCE = '@DASHBOARD_SCREEN/SET_NEXT_SEQUENCE',
}

export interface SelectedDashboardScreen {
  label: string
  value: string
}

export interface SelectedDashboard {
  label: string
  value: string
}

export interface NextSequenceScreen {
  DASHTELAS_SEQ: string
}

export interface DashboardScreenState {
  nextSequenceScreen?: NextSequenceScreen
  selectedScreen?: SelectedDashboardScreen
  selectedDashboard?: SelectedDashboard
  screenList: UncDashboard.DashboardScreen[]
  dashboardScreen: UncDashboard.ComposedTypes.GetDashboardScreen[]
}

export interface RequestFetchDashboardScreensAction {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_FETCH
}

export interface DashboardScreenListState {
  dashboardScreen: UncDashboard.ComposedTypes.FullDashboardScreen[]
}

export interface RequestFetchDashboardScreens {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_FETCH_FULL
}

export interface RequestCreateDashboardScreenAction {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_CREATE
  payload: {
    dash_codigo: number
    dashtelas_seq: number
    teladash_descricao: string
    teladash_analise: UncDashboard.ComposedTypes.teladash_analise[]
    successCallback?: () => void
  }
}

export interface RequestUpdateDashboardScreenAction {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_UPDATE
  payload: {
    dash_codigo: number
    dashtelas_seq: number
    teladash_codigo: number
    dashtelas_codigo: number
    teladash_descricao: string
    teladash_analise: UncDashboard.ComposedTypes.teladash_analise[]
    successCallback?: () => void
  }
}

export interface RequestRemoveDashboardScreenAction {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_REMOVE
  payload: {
    id: number
  }
}

export interface RequestGetNextSequence {
  type: DASHBOARD_SCREEN_TYPES.REQUEST_GET_NEXT_SEQUENCE
  payload: {
    id: number
  }
}

export interface SetFullDashboardScreenListAction {
  type: DASHBOARD_SCREEN_TYPES.SET_FULL_LIST
  payload: {
    dashboardScreen: UncDashboard.ComposedTypes.GetDashboardScreen[]
  }
}

export interface SetSelectedDashboardScreenAction {
  type: DASHBOARD_SCREEN_TYPES.SET_SELECTED_SCREEN
  payload: {
    selectedScreen?: SelectedDashboardScreen
  }
}
export interface SetSelectedDashboardAction {
  type: DASHBOARD_SCREEN_TYPES.SET_SELECTED_DASHBOARD
  payload: {
    selectedDashboard?: SelectedDashboard
  }
}

export interface SetDashboardScreenListAction {
  type: DASHBOARD_SCREEN_TYPES.SET_LIST
  payload: {
    screenList: UncDashboard.DashboardScreen[]
  }
}

export interface SetNextSequenceAction {
  type: DASHBOARD_SCREEN_TYPES.SET_NEXT_SEQUENCE
  payload: {
    nextSequenceScreen: NextSequenceScreen
  }
}
