import React from 'react'
import {
  FiArrowDown,
  FiCheck,
  FiClock,
  FiFileText,
  FiPause,
  FiPlay,
  FiTag,
  FiX,
} from 'react-icons/fi'

import {
  Container,
  Content,
  Actions,
  Title,
  Text,
  SituationText,
  MoreDetails,
  Children,
} from './styles'

export interface DashboardScreenProps {
  className?: string
  style?: React.CSSProperties
  description?: string
  duration?: string
  situationText?: string
  isActive?: boolean
  category?: string
  isAutomatic?: string
  rightSideComponent?: React.ReactNode
  moreDetails?: React.ReactNode
  children?: React.ReactNode
}

export const DashboardScreen: React.FC<DashboardScreenProps> = (props) => {
  const {
    style,
    duration,
    children,
    category,
    className,
    isAutomatic,
    moreDetails,
    description,
    situationText,
    isActive = false,
    rightSideComponent,
  } = props

  return (
    <Container className={className} style={style}>
      <Content>
        <Title>
          <FiFileText />
          <span>{description}</span>
        </Title>

        <Text>
          <FiTag />
          <span>{category}</span>
        </Text>

        <Text>
          <FiClock />
          <span>{duration}</span>
        </Text>

        {isAutomatic ? (
          <Text>
            {isAutomatic ? <FiPlay /> : <FiPause />}
            <span>{isAutomatic}</span>
          </Text>
        ) : null}

        <SituationText isActive={isActive}>
          {isActive ? <FiCheck /> : <FiX />}
          <span>{situationText}</span>
        </SituationText>

        {moreDetails ? (
          <MoreDetails>
            <FiArrowDown />
            <span>{moreDetails}</span>
          </MoreDetails>
        ) : null}

        {children ? <Children> {children} </Children> : null}
      </Content>

      <Actions>{rightSideComponent}</Actions>
    </Container>
  )
}
