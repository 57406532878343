import styled from 'styled-components'

import { Card } from 'src/components'

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

export const DashboardCard = styled(Card)`
  flex: 1;

  min-width: 20rem;

  margin: 0.8rem;
`
