import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  border-radius: 1rem;

  background: ${(props) => props.theme.background};
`

export const Title = styled.div`
  text-align: center;
  font-weight: 900;
  font-size: 1.8rem;
  color: ${(props) => props.theme.primaryText};

  padding: 1.2rem 1.6rem;

  border-bottom: 0.4rem solid ${(props) => props.theme.snow};
`

export const Content = styled.div`
  padding: 1.6rem;

  width: 100%;
  height: 100%;

  font-size: 1.7rem;

  color: ${(props) => props.theme.primaryTextBlack};
`
