import { call, put, takeLatest } from 'redux-saga/effects'

import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { showSuccessToast } from 'src/store/ducks/success'
import {
  AxiosServerResponse,
  DASHBOARD_SCREEN_ROUTES,
  Server,
} from 'src/services'

import {
  DASHBOARD_SCREEN_TYPES,
  RequestRemoveDashboardScreenAction,
} from './types'
// import { getDashboardScreenList } from './selects'
import { requestFetchFullDashboardScreen } from './actions'

type RequestData = Pick<
  UncDashboard.ComposedTypes.FullDashboardScreen,
  'teladash_codigo'
>

export function* requestRemoveDashboardScreenWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.REQUEST_REMOVE,
    handleRemoveDashboardScreen,
  )
}

export function* handleRemoveDashboardScreen(
  action: RequestRemoveDashboardScreenAction,
) {
  try {
    yield put(addLoading(DASHBOARD_SCREEN_TYPES.REQUEST_REMOVE))

    const { id } = action.payload

    const routesArray = [DASHBOARD_SCREEN_ROUTES.DASHBOARD_TELAS, id]
    const { data }: AxiosServerResponse<RequestData> = yield call(
      Server.delete,
      routesArray.join('/'),
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'removeDashboardScreen' }))

      // const dashboardScreens: UncDashboard.ComposedTypes.GetDashboardScreen[] = yield select(
      //   getDashboardScreenList,
      // )

      // const updatedDashboardScreen = dashboardScreens.map((item) => {
      //   const 0 = item.LISTA_TELAS.filter((it) => {
      //     return it.dashtelas_codigo !== id
      //   })
      //   return troll
      // })

      // yield put(setFullDashboardScreenList(updatedDashboardScreen))

      yield put(requestFetchFullDashboardScreen())
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(DASHBOARD_SCREEN_TYPES.REQUEST_REMOVE))
  }
}
