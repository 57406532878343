import React from 'react'
import { FiPieChart } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

import { Spinner, SpinnerWithText } from 'src/components'
import { useIsLoading, useTypedSelector } from 'src/hooks'
import { AUTO_DASHBOARD_TYPES } from 'src/store/ducks/autoDashboard'

import { Container, SpinnerContainer, NoDashboardData } from './styles'

const ValidationWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation('AutomaticDashboard')

  const isLoadingSummary = useIsLoading(
    AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY,
  )

  const hasNoDataToShow = useTypedSelector(
    ({ AutoDashboard }) => !AutoDashboard.summary,
  )

  if (isLoadingSummary) {
    return (
      <Container>
        <SpinnerContainer>
          <SpinnerWithText hasSpinnerOnTheLeft>
            <Spinner />
            <span>{t('loadingText')}</span>
          </SpinnerWithText>
        </SpinnerContainer>
      </Container>
    )
  }

  if (hasNoDataToShow) {
    return (
      <Container>
        <NoDashboardData
          iconComponent={<FiPieChart />}
          message={t('noDataToShow')}
        />
      </Container>
    )
  }

  return <Container>{children}</Container>
}

export default ValidationWrapper
