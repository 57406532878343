import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FiCheckCircle, FiSlash, FiTrash } from 'react-icons/fi'

import { useIsLoading, useRebuildTooltips, useTypedSelector } from 'src/hooks'
import {
  ConfirmModal,
  DangerRoundIconButton,
  DefaultTooltip,
  RoundIconButton,
  Spinner,
} from 'src/components'
import {
  requestFetchDashboardTypes,
  setDashboardTypesList,
  requestRemoveDashboardType,
  requestToggleDashboardTypeSituation,
  DASHBOARD_TYPE_TYPES,
} from 'src/store/ducks/dashboardType'

import {
  Actions,
  List,
  StyledDashboardTypeItem,
  StyledSpinnerWithText,
} from './styles'

interface DashboardTypeListProps {
  setEditingDashboardType: React.Dispatch<
    React.SetStateAction<UncDashboard.Dashboard | null>
  >
  editingDashboardType: UncDashboard.Dashboard | null
}

const DashboardTypeList: React.FC<DashboardTypeListProps> = ({
  setEditingDashboardType,
  editingDashboardType,
}) => {
  const { t } = useTranslation([
    'CreateDashboardType',
    'DashboardCategories',
    'Common',
    'DateTime',
    'Glossary',
  ])

  const [dashboardTypeId, setDashboardTypeId] = useState<number | null>(null)
  const dispatch = useDispatch()
  useRebuildTooltips()

  const dashboardTypes = useTypedSelector(({ DashboardType }) => {
    return DashboardType.dashboardTypes
  })

  const isLoading = useIsLoading(
    DASHBOARD_TYPE_TYPES.REQUEST_FETCH_DASHBOARD_TYPE,
  )

  const handleDeleteDashboardType = () => {
    if (!dashboardTypeId) return

    dispatch(
      requestRemoveDashboardType({
        id: dashboardTypeId,
      }),
    )
  }

  useEffect(() => {
    dispatch(requestFetchDashboardTypes())
    return () => {
      dispatch(setDashboardTypesList([]))
    }
  }, [dispatch])

  return (
    <>
      <ConfirmModal
        confirmButtonType="danger"
        isShowing={!!dashboardTypeId}
        title={t('deleteModalTitle')}
        message={t('deleteModalMessage')}
        onConfirm={handleDeleteDashboardType}
        cancelButtonText={t('Glossary:cancel')}
        confirmButtonText={t('Glossary:delete')}
        iconComponent={<FiTrash size="2.4rem" />}
        handleHideModal={() => setDashboardTypeId(null)}
      />

      <DefaultTooltip />

      {isLoading ? (
        <StyledSpinnerWithText hasSpinnerOnTheLeft>
          <Spinner />
          <span>{t('Glossary:loading')}</span>
        </StyledSpinnerWithText>
      ) : (
        <List>
          {dashboardTypes.map((dashboardItem) => {
            const {
              dash_codigo,
              dash_automatico,
              dash_descricao,
              dash_situacao,
              dash_tipo,
              dash_tempotelas,
            } = dashboardItem

            const isSelected = dash_codigo === editingDashboardType?.dash_codigo
            const isAutomatic = dash_automatico === 'S'
            const isActive = dash_situacao === 'A'

            const situationText = t('listItemSituation', {
              situation: t(isActive ? 'Glossary:active' : 'Glossary:inactive'),
            })

            const durationText = t('listItemDuration', {
              duration: t('DateTime:secondsShort', {
                seconds: Number(dash_tempotelas || 0),
              }),
            })

            const automaticText = t(
              isAutomatic ? 'listItemAutomatic' : 'listItemNotAutomatic',
            )

            const changeSituationButtonTooltip = t(
              isActive ? 'Glossary:deactivate' : 'Glossary:activate',
            )

            const categoryText = t('listItemCategory', {
              category: t(`DashboardCategories:${dash_tipo}`),
            })

            const handleSelectDashboard = () => {
              setEditingDashboardType(isSelected ? null : dashboardItem)
            }

            const handleToggleDashboardType = (e: React.MouseEvent) => {
              e.stopPropagation()
              dispatch(
                requestToggleDashboardTypeSituation({
                  id: dash_codigo,
                  situation: isActive ? 'I' : 'A',
                }),
              )
            }

            const handleDeleteDashboardType = (e: React.MouseEvent) => {
              e.stopPropagation()
              setDashboardTypeId(dash_codigo)
            }

            return (
              <StyledDashboardTypeItem
                key={dash_codigo}
                isActive={isActive}
                isSelected={isSelected}
                category={categoryText}
                duration={durationText}
                isAutomatic={automaticText}
                description={dash_descricao}
                situationText={situationText}
                onClick={handleSelectDashboard}
                rightSideComponent={
                  <Actions>
                    <RoundIconButton
                      data-tip={changeSituationButtonTooltip}
                      onClick={handleToggleDashboardType}
                    >
                      {isActive ? <FiSlash /> : <FiCheckCircle />}
                    </RoundIconButton>

                    <DangerRoundIconButton
                      data-tip={t('Glossary:delete')}
                      onClick={handleDeleteDashboardType}
                    >
                      <FiTrash />
                    </DangerRoundIconButton>
                  </Actions>
                }
              />
            )
          })}
        </List>
      )}
    </>
  )
}

export default DashboardTypeList
