import styled from 'styled-components'

import { ChartContainer } from 'src/components'

export const Chart = styled(ChartContainer)`
  flex: 1 45%;

  margin: 0.8rem;

  min-width: 30rem;
`

export const FullWidthChart = styled(ChartContainer)`
  flex: 1;

  margin: 0.8rem;
`
