import { Reducer } from 'redux'

import { DASHBOARD_TYPE_TYPES, DashboardTypeState } from './types'

export const initialState: DashboardTypeState = {
  dashboardTypes: [],
}

export const DashboardTypeReducer: Reducer<DashboardTypeState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case DASHBOARD_TYPE_TYPES.SET_DASHBOARD_TYPES_LIST: {
      return {
        ...state,
        dashboardTypes: payload.dashboardTypes,
      }
    }

    default:
      return state
  }
}
