import styled from 'styled-components'

import { ChartLegend } from 'src/components/ChartLegend'

export const BarLegend = styled(ChartLegend)`
  margin-bottom: 0.2rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    margin-bottom: 0.8rem;

    & > *:not(:first-child) {
      margin-left: 0;
    }
  }
`
