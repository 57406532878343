import { OptionsType } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { DASHBOARD_CATEGORIES } from 'src/config'
import { ReactSelectOption } from 'src/components'

type DashboardCategoryOption = ReactSelectOption & {
  value: UncDashboard.UnionTypes.DashboardCategory
}

export const useDashboardCategories = () => {
  const { t } = useTranslation('DashboardCategories')

  const dashboardCategoryOptions: OptionsType<DashboardCategoryOption> = useMemo(() => {
    return Object.values(DASHBOARD_CATEGORIES).map((dashboardCategory) => ({
      label: t(dashboardCategory, {
        defaultValue: dashboardCategory,
      }),
      value: dashboardCategory as UncDashboard.UnionTypes.DashboardCategory,
    }))
  }, [t])

  return {
    dashboardCategoryOptions,
  }
}
