import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiMenu } from 'react-icons/fi'

import { LogoHome } from 'src/assets'

import { Navbar, DrawerToggler, NavbarTitle, Logo } from './styles'

interface MainNavbarProps {
  handleToggleDrawer: () => void
}

const MainNavbar: React.FC<MainNavbarProps> = ({ handleToggleDrawer }) => {
  const { t } = useTranslation('Common')

  return (
    <Navbar>
      <DrawerToggler onClick={handleToggleDrawer}>
        <FiMenu />
      </DrawerToggler>

      <Logo src={LogoHome} alt={t('appName')} />
      <NavbarTitle>{t('appName')}</NavbarTitle>
    </Navbar>
  )
}

export default MainNavbar
