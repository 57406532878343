import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiMonitor, FiPlusCircle } from 'react-icons/fi'
import { useDispatch } from 'react-redux'

import { useToggleFunctions, useSetBrowserTabTitle } from 'src/hooks'
import {
  requestFetchFullDashboardScreen,
  setFullDashboardScreenList,
} from 'src/store/ducks/dashboardScreen'

import DashboardScreenForm from './DashboardScreenForm'
import DashboardScreenList from './DashboardScreenList'
import { Container, StyledPageTitle, CreateScreenButton } from './styles'
import { DashboardScreenType } from './type'

const DashboardScreen: React.FC = () => {
  useSetBrowserTabTitle('createDashboardScreen')

  const { t } = useTranslation('CreateDashboardScreen')

  const dispatch = useDispatch()

  const [
    editingDashboardScreen,
    setEditingDashboardScreen,
  ] = useState<DashboardScreenType | null>(null)

  const [
    isShowingScreenForm,
    handleShowScreenForm,
    handleHideScreenForm,
  ] = useToggleFunctions()

  useEffect(() => {
    if (editingDashboardScreen) handleShowScreenForm()
    else handleHideScreenForm()
  }, [editingDashboardScreen, handleShowScreenForm, handleHideScreenForm])

  useEffect(() => {
    dispatch(requestFetchFullDashboardScreen())

    return () => {
      dispatch(setFullDashboardScreenList([]))
    }
  }, [dispatch])

  return (
    <Container>
      <StyledPageTitle
        iconComponent={<FiMonitor />}
        title={t('title')}
        subtitle={t('subtitle')}
      />

      {isShowingScreenForm ? (
        <DashboardScreenForm
          editingDashboardScreen={editingDashboardScreen}
          setEditingDashboardScreen={setEditingDashboardScreen}
          handleHideScreenForm={handleHideScreenForm}
        />
      ) : (
        <CreateScreenButton onClick={handleShowScreenForm}>
          <span>{t('createScreenButton')}</span>
          <FiPlusCircle />
        </CreateScreenButton>
      )}

      <DashboardScreenList
        editingDashboardScreen={editingDashboardScreen}
        setEditingDashboardScreen={setEditingDashboardScreen}
      />
    </Container>
  )
}

export default DashboardScreen
