import { Reducer } from 'redux'

import { ANALYSIS_TYPE_TYPES, AnalysisTypeState } from './types'

export const initialState: AnalysisTypeState = {
  analysisTypes: [],
}

export const AnalysisTypeReducer: Reducer<AnalysisTypeState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case ANALYSIS_TYPE_TYPES.SET_ANALYSIS_TYPES_LIST: {
      return {
        ...state,
        analysisTypes: payload.analysisTypes,
      }
    }

    default:
      return state
  }
}
