import { call, put, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, LOGIN_ROUTES, Server } from 'src/services'

import { PASSWORD_TYPES, RequestResetPasswordAction } from './types'

type RequestData = {
  SENHA: string
  CONFIRMA_SENHA: string
  HASH: string
}

export function* requestResetPasswordWatcher() {
  yield takeLatest(PASSWORD_TYPES.REQUEST_RESET_PASSWORD, handleResetPassword)
}

export function* handleResetPassword(action: RequestResetPasswordAction) {
  try {
    yield put(addLoading(PASSWORD_TYPES.REQUEST_RESET_PASSWORD))
    const { hash, password, successCallback } = action.payload

    const requestData: RequestData = {
      SENHA: password,
      CONFIRMA_SENHA: password,
      HASH: hash,
    }

    const { data }: AxiosServerResponse = yield call(
      Server.put,
      LOGIN_ROUTES.TROCAR_SENHA,
      requestData,
    )

    if (data.isSuccessful) {
      yield put(showSuccessToast({ messageCode: 'resetPassword' }))
      if (successCallback) successCallback()
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(PASSWORD_TYPES.REQUEST_RESET_PASSWORD))
  }
}
