import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, COMPANY_ROUTES, Server } from 'src/services'

import { COMPANY_TYPES } from './types'
import { setCompanyList } from './actions'

export function* requestFetchCompaniesWatcher() {
  yield takeLatest(COMPANY_TYPES.REQUEST_FETCH_COMPANIES, handleFetchCompanies)
}

export function* handleFetchCompanies() {
  try {
    yield put(addLoading(COMPANY_TYPES.REQUEST_FETCH_COMPANIES))

    const { data }: AxiosServerResponse<UncDashboard.Company> = yield call(
      Server.put,
      COMPANY_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setCompanyList(data.data))
    } else {
      yield put(setCompanyList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(COMPANY_TYPES.REQUEST_FETCH_COMPANIES))
  }
}
