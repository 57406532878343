import { combineReducers } from 'redux'

import { AuthReducer } from './ducks/auth'
import { ErrorReducer } from './ducks/error'
import { LoadingReducer } from './ducks/loading'
import { SuccessReducer } from './ducks/success'
import { CompanyReducer } from './ducks/company'
import { DashboardReducer } from './ducks/dashboard'
import { SalesTypeReducer } from './ducks/salesType'
import { AnalysisTypeReducer } from './ducks/analysisType'
import { AuthorizationReducer } from './ducks/authorization'
import { AutoDashboardReducer } from './ducks/autoDashboard'
import { DashboardTypeReducer } from './ducks/dashboardType'
import { DashboardScreenReducer } from './ducks/dashboardScreen'
import { AnalysisReducer } from './ducks/analysis'

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Error: ErrorReducer,
  Loading: LoadingReducer,
  Success: SuccessReducer,
  Company: CompanyReducer,
  Dashboard: DashboardReducer,
  SalesType: SalesTypeReducer,
  AnalysisReducer: AnalysisReducer,
  AnalysisType: AnalysisTypeReducer,
  Authorization: AuthorizationReducer,
  AutoDashboard: AutoDashboardReducer,
  DashboardType: DashboardTypeReducer,
  DashboardScreen: DashboardScreenReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
