export enum USER_ROUTES {
  USUARIO = 'TWSUsuario/Usuario',
}

export enum CUSTOMER_REGISTRY_ROUTES {
  CONSULTA_EMAIL_CLIENTE = 'TWSCliente_Registro/ConsultaEmailCliente',
}

export enum LOGIN_ROUTES {
  LOGIN_EMAIL = 'TWSLogin/LoginEmail',
  RESETAR_SENHA = 'TWSLogin/ResetarSenha',
  TROCAR_SENHA = 'TWSLogin/TrocarSenha',
}

export enum DASHBOARD_ROUTES {
  GET_GRAFICOS = 'TWSDashboard/GetGraficos',
  GET_GRAFICOS_RESUMIDO = 'TWSDashboard/GetGraficosResumido',
  GET_POR_CAMPOS = 'TWSDashboard/GetPorCampos',
  DASHBOARD = 'TWSDashboard/Dashboard',
  PATCH_DASHBOARD = 'TWSDashboard/PatchDashboard',
}

export enum ANALYSIS_ROUTES {
  ANALISE = 'TWSAnalise/Analise',
  GET_POR_CAMPOS = 'TWSAnalise/GetPorCampos',
}

export enum DASHBOARD_SCREEN_ROUTES {
  GET_POR_CAMPOS = 'TWSTela_Dashboard/GetPorCampos',
  GET_LISTA = 'TWSDashboard_Telas/GetLista',
  DASHBOARD_TELAS = 'TWSDashboard_Telas/Dashboard_Telas',
  PATCH_DASHBOARD_TELAS = 'TWSDashboard_Telas/PatchDashboard_Telas',
  GET_PROXIMA_SEQUENCIA = 'TWSDashboard_Telas/GetProximaSequencia',
}

export enum ANALYSIS_TYPE_ROUTES {
  GET_POR_CAMPOS = 'TWSTipo_Analise/GetPorCampos',
}

export enum COMPANY_ROUTES {
  GET_POR_CAMPOS = 'TWSEmpresa/GetPorCampos',
}

export enum SALES_TYPE_ROUTES {
  GET_POR_CAMPOS = 'TWSTipo_PedVenda/GetPorCampos',
}
