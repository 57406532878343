import {
  PASSWORD_TYPES,
  RequestForgotPasswordAction,
  RequestResetPasswordAction,
} from './types'

export const requestForgotPassword = (
  email: string,
): RequestForgotPasswordAction => ({
  type: PASSWORD_TYPES.REQUEST_FORGOT_PASSWORD,
  payload: {
    email,
  },
})

export const requestResetPassword = (
  payload: RequestResetPasswordAction['payload'],
): RequestResetPasswordAction => ({
  type: PASSWORD_TYPES.REQUEST_RESET_PASSWORD,
  payload,
})
