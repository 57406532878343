import { call, put, takeLatest } from 'redux-saga/effects'

import { LOCAL_STORAGE_KEYS } from 'src/config'
import { showError } from 'src/store/ducks/error'

import {
  DASHBOARD_SCREEN_TYPES,
  SetSelectedDashboardScreenAction,
} from './types'

export function* requestSaveSelectedDashboardScreenWatcher() {
  yield takeLatest(
    DASHBOARD_SCREEN_TYPES.SET_SELECTED_SCREEN,
    handleSaveSelectedDashboardScreen,
  )
}

export function* handleSaveSelectedDashboardScreen(
  action: SetSelectedDashboardScreenAction,
) {
  try {
    const { selectedScreen } = action.payload
    const json = selectedScreen ? JSON.stringify(selectedScreen) : ''

    yield call(
      [localStorage, localStorage.setItem],
      LOCAL_STORAGE_KEYS.SELECTED_DASHBOARD_SCREEN,
      json,
    )
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  }
}
