import {
  AUTO_DASHBOARD_TYPES,
  RequestFetchSummaryAction,
  RequestFetchAutoDashboardInitialDataAction,
  SetSummaryAction,
  SetScreenListAction,
  SetCurrentScreenIndexAction,
  SetScreenAtIndexAction,
  GoToNextScreenAction,
  GoToPreviousScreenAction,
} from './types'

export const requestFetchSummary = (
  dashboardId: number | {},
): RequestFetchSummaryAction => ({
  type: AUTO_DASHBOARD_TYPES.REQUEST_FETCH_SUMMARY,
  payload: {
    dashboardId,
  },
})

export const requestFetchAutoDashboardInitialData = (): RequestFetchAutoDashboardInitialDataAction => ({
  type: AUTO_DASHBOARD_TYPES.REQUEST_FETCH_INITIAL_DATA,
})

export const setSummary = (
  summary?: UncDashboard.ComposedTypes.DashboardData,
): SetSummaryAction => ({
  type: AUTO_DASHBOARD_TYPES.SET_SUMMARY,
  payload: {
    summary,
  },
})

export const setScreenList = (
  screenList: UncDashboard.ComposedTypes.DashboardDataScreen[],
): SetScreenListAction => ({
  type: AUTO_DASHBOARD_TYPES.SET_SCREEN_LIST,
  payload: {
    screenList,
  },
})

export const setCurrentScreenIndex = (
  currentScreenIndex: number,
): SetCurrentScreenIndexAction => ({
  type: AUTO_DASHBOARD_TYPES.SET_CURRENT_SCREEN_INDEX,
  payload: {
    currentScreenIndex,
  },
})

export const setScreenAtIndex = (
  screen: UncDashboard.ComposedTypes.DashboardDataScreen,
  index: number,
): SetScreenAtIndexAction => ({
  type: AUTO_DASHBOARD_TYPES.SET_SCREEN_AT_INDEX,
  payload: {
    screen,
    index,
  },
})

export const goToNextScreen = (): GoToNextScreenAction => ({
  type: AUTO_DASHBOARD_TYPES.GO_TO_NEXT_SCREEN,
})

export const goToPreviousScreen = (): GoToPreviousScreenAction => ({
  type: AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN,
})
