export enum DASHBOARD_TYPES {
  REQUEST_GET_DATA = '@DASHBOARD/REQUEST_GET_DATA',
  SET_DATA = '@DASHBOARD/SET_DATA',
}

export interface DashboardState {
  dashboardData?: UncDashboard.ComposedTypes.DashboardData
}

export interface RequestGetDashboardDataAction {
  type: DASHBOARD_TYPES.REQUEST_GET_DATA
  payload: {
    dashboardScreenId: number
  }
}

export interface SetDashboardDataAction {
  type: DASHBOARD_TYPES.SET_DATA
  payload: {
    dashboardData?: UncDashboard.ComposedTypes.DashboardData
  }
}
