import styled from 'styled-components'

import { PageContainer } from 'src/components'

export const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;

  min-height: 100%;
`
