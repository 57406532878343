import styled from 'styled-components'

import { AccentButton, PageContainer, PageTitle } from 'src/components'

export const Container = styled(PageContainer)`
  max-width: 70rem;
  margin: 0 auto;
`

export const StyledPageTitle = styled(PageTitle)`
  margin-bottom: 4.8rem;
`

export const CreateScreenButton = styled(AccentButton)``
