import { Reducer } from 'redux'

import { SALES_TYPE_TYPES, SalesTypeState } from './types'

export const initialState: SalesTypeState = {
  salesTypes: [],
}

export const SalesTypeReducer: Reducer<SalesTypeState> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action

  switch (type) {
    case SALES_TYPE_TYPES.SET_SALES_TYPES_LIST: {
      return {
        ...state,
        salesTypes: payload.salesTypes,
      }
    }

    default:
      return state
  }
}
