import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiHexagon, FiPlusCircle } from 'react-icons/fi'

import { useToggleFunctions, useSetBrowserTabTitle } from 'src/hooks'

import CreateDashboardForm from './DashboardTypeForm'
import DashboardTypesList from './DashboardTypeList'
import { Container, StyledPageTitle, CreateDashboardButton } from './styles'

const CreateDashboardType: React.FC = () => {
  useSetBrowserTabTitle('createDashboardType')

  const { t } = useTranslation('CreateDashboardType')

  const [
    editingDashboardType,
    setEditingDashboardType,
  ] = useState<UncDashboard.Dashboard | null>(null)

  const [
    isShowingDashboardTypeForm,
    handleShowDashboardTypeForm,
    handleHideDashboardTypeForm,
  ] = useToggleFunctions()

  useEffect(() => {
    if (editingDashboardType) handleShowDashboardTypeForm()
    else handleHideDashboardTypeForm()
  }, [
    editingDashboardType,
    handleShowDashboardTypeForm,
    handleHideDashboardTypeForm,
  ])

  return (
    <Container>
      <StyledPageTitle
        iconComponent={<FiHexagon />}
        title={t('title')}
        subtitle={t('subtitle')}
      />

      {isShowingDashboardTypeForm ? (
        <CreateDashboardForm
          editingDashboardType={editingDashboardType}
          setEditingDashboardType={setEditingDashboardType}
          handleHideDashboardTypeForm={handleHideDashboardTypeForm}
        />
      ) : (
        <CreateDashboardButton onClick={handleShowDashboardTypeForm}>
          <span>{t('createNewDashboardType')}</span>
          <FiPlusCircle />
        </CreateDashboardButton>
      )}

      <DashboardTypesList
        editingDashboardType={editingDashboardType}
        setEditingDashboardType={setEditingDashboardType}
      />
    </Container>
  )
}

export default CreateDashboardType
