import { useTypedSelector } from './useTypedSelector'

export const useIsShowingSuccess = (key: string) => {
  const isShowingSuccess = useTypedSelector(({ Success }) => {
    const success = Success.success
    return !!success && !!success[key]
  })

  return isShowingSuccess
}
