import { call, put, select, takeLatest } from 'redux-saga/effects'

import { showSuccessToast } from 'src/store/ducks/success'
import { showError, showServerError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import {
  serverStructureFactory,
  AxiosServerResponse,
  DASHBOARD_ROUTES,
  Server,
} from 'src/services'

import { getDashboardTypeList } from './selects'
import { setDashboardTypesList } from './actions'
import {
  DASHBOARD_TYPE_TYPES,
  RequestToggleDashboardTypeSituationAction,
} from './types'

type RequestData = Pick<UncDashboard.Dashboard, 'dash_situacao' | 'dash_codigo'>

export function* requestToggleDashboardTypeSituationWatcher() {
  yield takeLatest(
    DASHBOARD_TYPE_TYPES.REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION,
    handleToggleDashboardTypeSituation,
  )
}

export function* handleToggleDashboardTypeSituation(
  action: RequestToggleDashboardTypeSituationAction,
) {
  try {
    yield put(
      addLoading(DASHBOARD_TYPE_TYPES.REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION),
    )

    const { payload } = action

    const requestData: RequestData = {
      dash_codigo: payload.id,
      dash_situacao: payload.situation,
    }

    const { data }: AxiosServerResponse<UncDashboard.Dashboard> = yield call(
      Server.post,
      DASHBOARD_ROUTES.PATCH_DASHBOARD,
      serverStructureFactory(requestData),
    )

    if (data.isSuccessful) {
      const isActivated = payload.situation === 'A'
      yield put(
        showSuccessToast({
          messageCode: isActivated
            ? 'activateDashboardType'
            : 'deactivateDashboardType',
        }),
      )

      const updatedDashboardType = data.getFirstData()
      if (!updatedDashboardType) return
      const dashboardTypes: UncDashboard.Dashboard[] = yield select(
        getDashboardTypeList,
      )

      const updatedDashboardTypeList = dashboardTypes.map((dashboardType) => {
        if (dashboardType.dash_codigo === updatedDashboardType.dash_codigo) {
          return updatedDashboardType
        }

        return dashboardType
      })

      yield put(setDashboardTypesList(updatedDashboardTypeList))
    } else if (data.messages.length) {
      yield put(showServerError(data.messages))
    } else {
      throw new Error()
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(
      removeLoading(
        DASHBOARD_TYPE_TYPES.REQUEST_TOGGLE_DASHBOARD_TYPE_SITUATION,
      ),
    )
  }
}
