import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  FiCheck,
  FiCheckCircle,
  FiClock,
  FiFileText,
  FiTrash,
  FiTag,
} from 'react-icons/fi'

import {
  DefaultError,
  DefaultLabel,
  ReactSelectOption,
  SnowInput,
  SnowSelect,
  WhiteSpinner,
} from 'src/components'
import { useDashboardCategories, useIsLoading } from 'src/hooks'
import {
  DASHBOARD_TYPE_TYPES,
  requestCreateDashboardType,
  requestUpdateDashboardType,
} from 'src/store/ducks/dashboardType'

import {
  Container,
  Form,
  StyledInput,
  HideFormButton,
  StyledCheckBox,
  SaveDashboardTypeButton,
} from './styles'

interface DashboardTypeFormProps {
  handleHideDashboardTypeForm: () => void
  setEditingDashboardType: React.Dispatch<
    React.SetStateAction<UncDashboard.Dashboard | null>
  >
  editingDashboardType: UncDashboard.Dashboard | null
}

type DashboardCategoryOption = ReactSelectOption & {
  value: UncDashboard.UnionTypes.DashboardCategory
}

const DashboardTypeForm: React.FC<DashboardTypeFormProps> = ({
  handleHideDashboardTypeForm,
  setEditingDashboardType,
  editingDashboardType,
}) => {
  const { t } = useTranslation(['CreateDashboardType', 'Glossary', 'Error'])
  const { dashboardCategoryOptions } = useDashboardCategories()
  const dispatch = useDispatch()

  const isLoading = useIsLoading(
    DASHBOARD_TYPE_TYPES.REQUEST_CREATE_DASHBOARD_TYPE,
    DASHBOARD_TYPE_TYPES.REQUEST_UPDATE_DASHBOARD_TYPE,
  )

  const descriptionRef = useRef<HTMLInputElement | null>(null)
  const durationRef = useRef<HTMLInputElement | null>(null)

  const [isAutomatic, setIsAutomatic] = useState(true)

  const [
    selectedDashboardCategory,
    setSelectedDashboardCategory,
  ] = useState<DashboardCategoryOption | null>(null)

  const [errors, setErrors] = useState({
    isDashboardTypeNotSelected: false,
    isDescriptionEmpty: false,
    isDurationEmpty: false,
  })

  const isEditing = useMemo(() => !!editingDashboardType, [
    editingDashboardType,
  ])

  const getUncontrolledValues = () => {
    const description = descriptionRef.current?.value || ''
    const duration = durationRef.current?.value || ''

    return {
      description,
      duration,
    }
  }

  const handleClearForm = () => {
    if (descriptionRef.current) descriptionRef.current.value = ''
    if (durationRef.current) durationRef.current.value = ''
    setSelectedDashboardCategory(null)
    setIsAutomatic(true)
  }

  const handleValidation = (): boolean => {
    const { description, duration } = getUncontrolledValues()

    const errorsObject: typeof errors = {
      isDashboardTypeNotSelected: !selectedDashboardCategory,
      isDescriptionEmpty: !description.trim(),
      isDurationEmpty: !duration.trim(),
    }

    setErrors(errorsObject)
    return Object.values(errorsObject).every((hasError) => !hasError)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (handleValidation()) {
      const { description, duration } = getUncontrolledValues()
      const dashboardCategory = selectedDashboardCategory?.value
      if (!dashboardCategory) return

      if (editingDashboardType) {
        dispatch(
          requestUpdateDashboardType({
            id: editingDashboardType.dash_codigo,
            situation: editingDashboardType.dash_situacao,
            description,
            duration,
            isAutomatic,
            dashboardCategory,
            successCallback() {
              setEditingDashboardType(null)
            },
          }),
        )
      } else {
        dispatch(
          requestCreateDashboardType({
            dashboardCategory,
            description,
            duration,
            isAutomatic,
            successCallback: handleClearForm,
          }),
        )
      }
    }
  }

  const handleToggleAutomatic = () => {
    setIsAutomatic((isAutomatic) => {
      return !isAutomatic
    })
  }

  const handleChangeDashboardCategory = (option: any) => {
    const dashboardCategoryOption = option as DashboardCategoryOption
    setSelectedDashboardCategory(dashboardCategoryOption)
  }

  const handleHideForm = () => {
    if (editingDashboardType) {
      setEditingDashboardType(null)
    } else {
      handleHideDashboardTypeForm()
    }
  }

  useEffect(() => {
    if (editingDashboardType) {
      const dashboardCategoryFound = dashboardCategoryOptions.find(
        (dashboardType) => {
          return dashboardType.value === editingDashboardType.dash_tipo
        },
      )

      if (!dashboardCategoryFound) return

      setSelectedDashboardCategory(dashboardCategoryFound)
    }
  }, [editingDashboardType, dashboardCategoryOptions])

  useEffect(() => {
    if (editingDashboardType) {
      const isDashboardAutomatic = editingDashboardType.dash_automatico === 'S'
      setIsAutomatic(isDashboardAutomatic)
    }
  }, [editingDashboardType])

  useEffect(() => {
    if (editingDashboardType && descriptionRef.current && durationRef.current) {
      const description = editingDashboardType.dash_descricao || ''
      const duration = String(editingDashboardType.dash_tempotelas) || '0'
      descriptionRef.current.value = description
      durationRef.current.value = duration
    }
  }, [editingDashboardType])

  return (
    <Container>
      <HideFormButton onClick={handleHideForm}>
        <span>
          {t(
            isEditing
              ? 'cancelEditingDashboardType'
              : 'cancelCreatingDashboardType',
          )}
        </span>
        <FiTrash />
      </HideFormButton>

      <Form onSubmit={handleSubmit} noValidate>
        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="description">
              <FiFileText />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('descriptionLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              ref={descriptionRef}
              id="description"
              type="text"
              autoCapitalize="off"
              placeholder={t('descriptionPh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isDescriptionEmpty}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="duration">
              <FiClock />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('durationLabel')}</span>
              <span className="label-hint">{t('durationHint')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowInput
              ref={durationRef}
              id="duration"
              type="number"
              min="0"
              autoCapitalize="off"
              placeholder={t('durationPh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isDurationEmpty}
        />

        <StyledInput
          labelComponent={
            <DefaultLabel htmlFor="dashboardCategory">
              <FiTag />
              <span className="required">{t('Glossary:required')}</span>
              <span>{t('dashboardCategoryLabel')}</span>
            </DefaultLabel>
          }
          inputComponent={
            <SnowSelect
              id="dashboardCategory"
              name="dashboardCategory"
              options={dashboardCategoryOptions}
              value={selectedDashboardCategory}
              onChange={handleChangeDashboardCategory}
              placeholder={t('dashboardCategoryPh')}
            />
          }
          errorComponent={
            <DefaultError>{t('Error:emptyFieldError')}</DefaultError>
          }
          showError={errors.isDashboardTypeNotSelected}
        />

        <StyledCheckBox
          checkMarkIcon={<FiCheck />}
          checked={isAutomatic}
          onChange={handleToggleAutomatic}
        >
          <span>{t('automatic')}</span>
        </StyledCheckBox>

        <SaveDashboardTypeButton type="submit" disabled={isLoading}>
          <span>
            {t(isEditing ? 'editDashboardType' : 'saveDashboardType')}
          </span>
          {isLoading ? <WhiteSpinner /> : <FiCheckCircle />}
        </SaveDashboardTypeButton>
      </Form>
    </Container>
  )
}

export default DashboardTypeForm
