import {
  SALES_TYPE_TYPES,
  RequestFetchSalesTypesAction,
  SetSalesTypesListAction,
} from './types'

export const requestFetchSalesTypes = (): RequestFetchSalesTypesAction => ({
  type: SALES_TYPE_TYPES.REQUEST_FETCH_SALES_TYPES,
})

export const setSalesTypesList = (
  salesTypes: UncDashboard.SalesType[],
): SetSalesTypesListAction => ({
  type: SALES_TYPE_TYPES.SET_SALES_TYPES_LIST,
  payload: {
    salesTypes,
  },
})
