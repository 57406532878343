import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FiChevronLeft, FiChevronRight, FiPause, FiPlay } from 'react-icons/fi'

import { useTimer, useTypedSelector } from 'src/hooks'
import {
  goToNextScreen,
  goToPreviousScreen,
} from 'src/store/ducks/autoDashboard'

import { Container, ControlButtons, ControlButton, TimerText } from './styles'

const Controls: React.FC = () => {
  const timer = useTimer(true)
  const dispatch = useDispatch()

  const screenTimeInSeconds = useTypedSelector(({ AutoDashboard }) => {
    return Number(AutoDashboard.summary?.dash_tempotelas || 0)
  })

  const handleGoToPreviousScreen = () => {
    dispatch(goToPreviousScreen())
    timer.handleSetMilliseconds(0)
  }

  const handleGoToNextScreen = () => {
    dispatch(goToNextScreen())
    timer.handleSetMilliseconds(0)
  }

  useEffect(() => {
    if (timer.milliseconds >= screenTimeInSeconds * 1000) {
      dispatch(goToNextScreen())
      timer.handleSetMilliseconds(0)
    }
  }, [screenTimeInSeconds, dispatch, timer])

  return (
    <Container>
      <TimerText>{`${timer.timerText} / ${screenTimeInSeconds}s`}</TimerText>

      <ControlButtons>
        <ControlButton onClick={handleGoToPreviousScreen}>
          <FiChevronLeft />
        </ControlButton>

        <ControlButton
          onClick={
            timer.isTimerRunning
              ? timer.handlePauseTimer
              : timer.handleStartTimer
          }
        >
          {timer.isTimerRunning ? <FiPause /> : <FiPlay />}
        </ControlButton>

        <ControlButton onClick={handleGoToNextScreen}>
          <FiChevronRight />
        </ControlButton>
      </ControlButtons>
    </Container>
  )
}

export default Controls
