import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, SALES_TYPE_ROUTES, Server } from 'src/services'

import { SALES_TYPE_TYPES } from './types'
import { setSalesTypesList } from './actions'

export function* requestFetchSalesTypesWatcher() {
  yield takeLatest(
    SALES_TYPE_TYPES.REQUEST_FETCH_SALES_TYPES,
    handleFetchSalesTypes,
  )
}

export function* handleFetchSalesTypes() {
  try {
    yield put(addLoading(SALES_TYPE_TYPES.REQUEST_FETCH_SALES_TYPES))

    const { data }: AxiosServerResponse<UncDashboard.SalesType> = yield call(
      Server.put,
      SALES_TYPE_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setSalesTypesList(data.data))
    } else {
      yield put(setSalesTypesList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(SALES_TYPE_TYPES.REQUEST_FETCH_SALES_TYPES))
  }
}
