import { call, put, select, takeEvery } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, DASHBOARD_ROUTES, Server } from 'src/services'

import { setScreenAtIndex } from './actions'
import { AUTO_DASHBOARD_TYPES } from './types'
import { getSummaryScreenList, getCurrentScreenIndex } from './selects'

type ScreenList = UncDashboard.ComposedTypes.DashboardDataScreen[]
type RequestData = Pick<UncDashboard.DashboardScreen, 'teladash_codigo'>
type Response = AxiosServerResponse<UncDashboard.ComposedTypes.DashboardData>

export function* requestFetchPreviousScreenWatcher() {
  yield takeEvery(
    AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN,
    handleFetchPreviousScreen,
  )
}

export function* handleFetchPreviousScreen() {
  try {
    yield put(addLoading(AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN))

    const currentScreenIndex: number = yield select(getCurrentScreenIndex)
    const screenList: ScreenList = yield select(getSummaryScreenList)

    const possiblePreviousIndex = currentScreenIndex - 1
    const isIndexSmallerThanZero = possiblePreviousIndex < 0
    const prevScreenIndex = isIndexSmallerThanZero
      ? screenList.length - 1
      : possiblePreviousIndex

    const screenId = screenList[prevScreenIndex].teladash_codigo

    const requestData: RequestData = {
      teladash_codigo: screenId,
    }

    const { data }: Response = yield call(
      Server.put,
      DASHBOARD_ROUTES.GET_GRAFICOS,
      requestData,
    )

    if (data.isSuccessful) {
      const firstData = data.getFirstData()
      const screenList = firstData?.LISTA_TELAS

      if (screenList) {
        const [firstScreen] = screenList
        if (firstScreen) {
          yield put(setScreenAtIndex(firstScreen, prevScreenIndex))
        }
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(AUTO_DASHBOARD_TYPES.GO_TO_PREVIOUS_SCREEN))
  }
}
