import { call, put, takeLatest } from 'redux-saga/effects'

import { showError } from 'src/store/ducks/error'
import { addLoading, removeLoading } from 'src/store/ducks/loading'
import { AxiosServerResponse, ANALYSIS_TYPE_ROUTES, Server } from 'src/services'

import { ANALYSIS_TYPE_TYPES } from './types'
import { setAnalysisTypesList } from './actions'

export function* requestFetchAnalysisTypesWatcher() {
  yield takeLatest(
    ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES,
    handleFetchAnalysisTypes,
  )
}

export function* handleFetchAnalysisTypes() {
  try {
    yield put(addLoading(ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES))

    const { data }: AxiosServerResponse<UncDashboard.AnalysisType> = yield call(
      Server.put,
      ANALYSIS_TYPE_ROUTES.GET_POR_CAMPOS,
      {},
    )

    if (data.isSuccessful) {
      yield put(setAnalysisTypesList(data.data))
    } else {
      yield put(setAnalysisTypesList([]))
    }
  } catch (e) {
    if (e instanceof Error) {
      yield put(showError(e.message ? [e.message] : []))
    } else {
      yield put(showError(['An unknown error occurred.']))
    }
  } finally {
    yield put(removeLoading(ANALYSIS_TYPE_TYPES.REQUEST_FETCH_ANALYSIS_TYPES))
  }
}
